import styled from 'styled-components';

import { IValueProps } from './types';

const Value = styled.div<IValueProps>`
  min-width: 22px;
  text-align: center;
  margin: 0 1rem;
  font-size: 0.85rem;
  text-transform: lowercase;
`;

export default Value;
