import { IBrandRoutes } from './types';
import { LazyRoute, lazyWithFallback } from './util';

const Catering: LazyRoute = lazyWithFallback(() => import('components/catering'));

export const brandRoutes: IBrandRoutes = {
  'routes.catering': Catering,
};

export const fullPageBrandRoutes: IBrandRoutes = {};

export default brandRoutes;
