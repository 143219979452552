import { ReactNode, createElement } from 'react';

import { IsoCountryCode2, Language } from '@rbilabs/intl';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';

import { AccessibilityProvider } from 'state/accessibility';
import { AuthProvider } from 'state/auth';
import { AuthGuestProvider } from 'state/auth-guest';
import { BluecodeProvider } from 'state/bluecode';
import { BranchProvider } from 'state/branch';
import { BrazeProvider } from 'state/braze';
import { CdpProvider } from 'state/cdp';
import { DayPartProvider } from 'state/day-part';
import { DeliveryConfirmationProvider } from 'state/delivery-confirmation';
import { ErrorsProvider } from 'state/errors';
import { FavoritesProvider } from 'state/favorites';
import { FeaturesProvider } from 'state/features';
import { GeolocationProvider } from 'state/geolocation';
import { ConfiguredStore } from 'state/global-state';
import { GraphQLProvider } from 'state/graphql';
import { IntlProvider } from 'state/intl';
import { LDProvider } from 'state/launchdarkly';
import { LoadingProvider } from 'state/loading';
import { LocationProvider } from 'state/location';
import { LoggerProvider } from 'state/logger/provider';
import { LoyaltyProvider } from 'state/loyalty';
import { MenuProvider } from 'state/menu';
import { MobileFooterProvider } from 'state/mobile-footer';
import { MobileHeaderNavProvider } from 'state/mobile-header-nav';
import { NearbyStoresProvider } from 'state/nearby-stores';
import { NetworkProvider } from 'state/network';
import { OrderProvider } from 'state/order';
import { PaymentProvider } from 'state/payment';
import { ScrollProvider } from 'state/scroll';
import { ServiceModeProvider } from 'state/service-mode';
import { PageManagerProvider } from 'state/static-page-manager';
import { StoreProvider } from 'state/store';
import { UIProvider } from 'state/ui';
import { LaunchDarklyFlagsObject } from 'utils/launchdarkly';

declare interface IProviderProps {
  children: ReactNode;
}
const providers = ({
  appLanguage,
  appCountry,
  basename,
  ldFlags,
  userHasSignedIn,
  userSession,
  store,
}: {
  appLanguage: Language;
  appCountry: IsoCountryCode2;
  basename?: string;
  ldFlags: LaunchDarklyFlagsObject;
  userHasSignedIn: boolean;
  userSession: CognitoUserSession | null;
  store: ConfiguredStore;
}) => {
  const ReduxProviderWithStore = ({ children }: IProviderProps) =>
    createElement(ReduxProvider, { store }, children);
  const LDProviderWithFlags = ({ children }: IProviderProps) => LDProvider({ children, ldFlags });
  const AuthProviderWithUserSession = ({ children }: IProviderProps) =>
    AuthProvider({ children, userHasSignedIn, userSession });
  const IntlProviderWithLocale = ({ children }: IProviderProps) =>
    IntlProvider({ appLanguage, appCountry, children });
  const LocationProviderWithBasename = ({ children }: IProviderProps) =>
    LocationProvider({ basename, children });

  return [
    ReduxProviderWithStore,
    LoggerProvider,
    LDProviderWithFlags,
    IntlProviderWithLocale,
    BluecodeProvider,
    LocationProviderWithBasename,
    ...(window.Cypress ? [] : [CdpProvider]),
    BranchProvider,
    GraphQLProvider,
    BrazeProvider,
    LoadingProvider,
    ErrorsProvider,
    NetworkProvider,
    AuthGuestProvider,
    AuthProviderWithUserSession,
    UIProvider,
    ServiceModeProvider,
    StoreProvider,
    FeaturesProvider,
    DayPartProvider,
    NearbyStoresProvider,
    LoyaltyProvider,
    MenuProvider,
    MobileHeaderNavProvider,
    MobileFooterProvider,
    PaymentProvider,
    PageManagerProvider,
    OrderProvider,
    FavoritesProvider,
    GeolocationProvider,
    AccessibilityProvider,
    HelmetProvider,
    DeliveryConfirmationProvider,
    ScrollProvider,
  ];
};

export default providers;
