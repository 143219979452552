import styled from 'styled-components';

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  grid-column: 1 / -1;
  padding-block-start: 0.5rem;
  margin-block-start: 0.5rem;
  border-block-start: 1px solid ${Styles.color.grey.four};
`;
