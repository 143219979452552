import styled from 'styled-components';

import { TileImage as StyledTileImage } from './marketing-tile-list.styled.default';

export * from './marketing-tile-list.styled.default';

export const TileImage = styled(StyledTileImage)`
  height: 3.75em;
  margin: 0;
  max-height: 3.75em;
  max-width: 3.75em;
`;
