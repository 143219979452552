import styled from 'styled-components';
import { PointsDisplayIcon } from 'ui-system/components/points-display';

import { StyledPointsDisplay as DefaultStyledPointsDisplay } from './rewards-link.styled.default';

export * from './rewards-link.styled.default';

export const StyledPointsDisplay = styled(DefaultStyledPointsDisplay)`
  color: ${p => p.theme.token('text-loyalty-highlight')};

  ${PointsDisplayIcon} path {
    fill: currentColor;
  }
`;
