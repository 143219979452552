import styled from 'styled-components';

export const LOGO_TITLE_KEY = 'plkLogoTitle';

export const StyledLogo = styled.div`
  width: 130px;
  display: flex;

  ${Styles.desktopLarge} {
    height: 42px;
    width: 150px;
    z-index: ${Styles.zIndex.top};
  }
`;
