import React from 'react';

import { useIntl } from 'react-intl';

import { routes } from 'utils/routing';

import { NavLink } from '../nav-link';

export const RecentOrdersLink: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <NavLink to={routes.orders} data-testid="recent-orders-link">
      {formatMessage({ id: 'recentOrders' })}
    </NavLink>
  );
};
