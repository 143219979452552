import React, { createContext, useContext, useMemo, useState } from 'react';

import { IBaseProps, IBringgUuids } from '@rbi-ctg/frontend';
import { IDeliveryDriver } from '@rbi-ctg/menu';

interface IDeliveryConfirmationContext {
  bringgUuids: IBringgUuids;
  setBringgUuids: (bringgUuids: IBringgUuids) => void;
  driver: IDeliveryDriver | null;
  setDriver: (driver: IDeliveryDriver | null) => void;
  deliveryVendor: string;
  setDeliveryVendor: (deliveryVendor: string) => void;
}

export const DeliveryConfirmationContext = createContext<IDeliveryConfirmationContext>(
  {} as IDeliveryConfirmationContext
);

export const useDeliveryConfirmationContext = () => useContext(DeliveryConfirmationContext);

export const DeliveryConfirmationProvider = ({ children }: IBaseProps) => {
  const [bringgUuids, setBringgUuids] = useState<IBringgUuids>({});
  const [driver, setDriver] = useState<IDeliveryDriver | null>(null);
  const [deliveryVendor, setDeliveryVendor] = useState<string>('');

  const value = useMemo(
    () => ({ bringgUuids, setBringgUuids, driver, setDriver, deliveryVendor, setDeliveryVendor }),
    [bringgUuids, driver, deliveryVendor]
  );

  return (
    <DeliveryConfirmationContext.Provider value={value}>
      {children}
    </DeliveryConfirmationContext.Provider>
  );
};

export default DeliveryConfirmationContext.Consumer;
