import { ApolloLink, Operation, createHttpLink, from, split } from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { RetryLink } from '@apollo/client/link/retry';
import { sha256 } from 'js-sha256';
import { isNil } from 'lodash';

import { SupportedLanguages, SupportedRegions } from '@rbi-ctg/frontend';
import { getGraphqlApiUrl, getGraphqlGatewayApiUrl } from 'remote/constants';

import {
  AUTH_GUEST_ALLOWED_DIRECTIVE,
  AUTH_REQUIRED_DIRECTIVE,
  isGuestUserLoggedIn,
  isRequestAllowAuthGuest,
  isRequestAuthProtected,
  isUserLoggedIn,
  stripAuthDirective,
} from './auth-required-directive';
import { blockedUserLink } from './blocked-user-link';
import { cancelRequestLink } from './cancel-request-link';
import { sanityFetch } from './sanity-fetch';
import { isGatewayRequest, stripGatewayDirective } from './strip-gateway-directive';
import { removeNestedTypenameFromMenuQueriesLink, stripTypenameLink } from './strip-typename';
import { isCacheRequest, stripUseCacheDirective } from './strip-useCache-directive';
import { withAuthGuestToken } from './with-auth-guest-token';
import { withAuthToken } from './with-auth-token';
import { withClientInfo } from './with-client-info-headers';
import { withErrorLogger } from './with-error-logger';
import { withForterHeaders } from './with-forter-headers';
import { withHeaders } from './with-headers';
import { withI18nContext } from './with-i18n-context';
import { withI18nHeaders } from './with-i18n-headers';
import { withLocalizedQueries } from './with-localized-queries';
import { withLogger } from './with-logger';
import { withMParticle } from './with-mparticle';
import { withSessionId } from './with-session-id';
import { withDateTimeHeaders } from './with-user-datetime-headers';

const isSanityRequest = (context?: Record<string, any>) => !isNil(context && context.uri);
const operationIsGatewayRequest = (operation: Operation) => isGatewayRequest(operation.query);
const operationIsSanityRequest = (operation: Operation) => isSanityRequest(operation.getContext());
const operationIsCacheRequest = (operation: Operation) => isCacheRequest(operation.query);

const fetchOptions = {
  referrerPolicy: 'no-referrer',
};

const sanityHttpLink = from([
  withLocalizedQueries(),
  new RetryLink({ attempts: { max: 3 } }),
  // sanity requests have the uri set in context
  from([
    removeNestedTypenameFromMenuQueriesLink,
    createHttpLink({ credentials: 'omit', fetch: sanityFetch, fetchOptions }),
  ]),
]);

const withRBIGraphQLLinks = from([
  withAuthGuestToken,
  withAuthToken,
  withI18nHeaders,
  withDateTimeHeaders,
  withForterHeaders,
  withSessionId,
  withClientInfo,
]);

const gatewayHttpLink = createHttpLink({
  uri: getGraphqlGatewayApiUrl(),
  credentials: 'omit',
});

const gatewaySplit = split(
  operationIsGatewayRequest,
  split(
    operationIsCacheRequest,
    from([
      stripGatewayDirective,
      stripUseCacheDirective,
      createPersistedQueryLink({ sha256, useGETForHashedQueries: true }),
      gatewayHttpLink,
    ]),
    from([stripGatewayDirective, gatewayHttpLink])
  ),
  from([
    stripGatewayDirective,
    split(
      /**
       * We avoid batching requests via BatchHttpLink when:
       * - context.shouldNotBatch = true
       * - app is run within Cypress. we want deterministic tests.
       */
      operation => operation.getContext().shouldNotBatch || !!window?.Cypress,
      createHttpLink({
        uri: getGraphqlApiUrl(),
        credentials: 'omit',
      }),
      new BatchHttpLink({
        uri: getGraphqlApiUrl(),
        credentials: 'omit',
      }) as ApolloLink
    ),
  ])
);

const fromStripAuthDirective = from([stripAuthDirective, gatewaySplit]);

const authRequestSplit = split(
  isUserLoggedIn,
  fromStripAuthDirective,
  split(
    isRequestAllowAuthGuest,
    split(
      isGuestUserLoggedIn,
      fromStripAuthDirective,
      cancelRequestLink(
        `@${AUTH_REQUIRED_DIRECTIVE} and @${AUTH_GUEST_ALLOWED_DIRECTIVE} directives present on query but user or guest is not authorized.`
      )
    ),
    cancelRequestLink(
      `@${AUTH_REQUIRED_DIRECTIVE} directive present on query but user is not authorized.`
    )
  )
);

/**
 * The lambdaHttpLink composes the link chain for requests going out to our
 * lambda backend. It can be difficult to visualize the chain with all the
 * splits, so here's a diagram:
 *
 *                         ...withRBIGraphQLLinks
 *                      is auth required on this request?
 *                         /                 \
 *                       yes                  no
 *                      /                      \
 *                 is user logged in?       gateway split
 *                  /           \
 *                yes           no
 *               /                \
 *          gateway split     is guest auth allowed?
 *                            /                 \
 *                         yes                  no
 *                         /                      \
 *                is guest user logged in?    cancel request
 *                /           \
 *              yes           no
 *             /                \
 *     gateway split        cancel request
 *
 *
 * we split out the gatewaySplit into a variable so that it can be re-used,
 * because there are now two different places where we might want to use it.
 * after composing the "base" links (`withRBIGraphQLLinks`), we first ask if
 * we're dealing with a query that required auth. if not, we send the request
 * down the pre-existing chain, which just has the gateway split as the next
 * step. if the query does require auth, we send it to this new step, which
 * then asks if the user is logged in. if not, we cancel the request and
 * return an error. if they are logged in, then we treat it as a "normal"
 * request and send it down the rest of the link chain, where the first
 * step is the gateway split.
 */
const lambdaHttpLink = from([
  withRBIGraphQLLinks,
  split(isRequestAuthProtected, authRequestSplit, gatewaySplit),
]);

const httpSplit = split(operationIsSanityRequest, sanityHttpLink, lambdaHttpLink);

export const link = from([
  withLogger,
  withMParticle,
  withErrorLogger,
  withI18nContext,
  withHeaders({ 'content-type': 'application/json' }),
  stripTypenameLink,
  httpSplit,
]);

// ensure language and region are set before any queries are fired
export const getConfiguredLink = (
  language: SupportedLanguages,
  region: SupportedRegions,
  enableUserBlocking: boolean
) => {
  withI18nContext.setLocale(language, region);

  const configuredLink = enableUserBlocking ? from([blockedUserLink, link]) : link;

  return configuredLink;
};
