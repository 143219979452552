import { getApiKey, isNativeAndroid, isNativeIOS } from 'utils/environment';

export const GOOGLE_MAPS_API_BASE_URL = 'https://maps.googleapis.com/maps/api';

export const GET_CURRENT_POSITION_TIMEOUT = 5000;

export const getGoogleMapsApiKey = () => {
  if (isNativeIOS()) {
    return getApiKey('googleMapsIOS');
  }

  if (isNativeAndroid()) {
    return getApiKey('googleMapsAndroid');
  }

  return getApiKey('googleMapsWeb');
};

export const getGoogleApiSrcPath = () => {
  const googleMapsApiKey = getGoogleMapsApiKey() || getApiKey('googleMaps');
  return `${GOOGLE_MAPS_API_BASE_URL}/js?key=${googleMapsApiKey}&libraries=places`;
};
