import { useState } from 'react';

import useEffectOnce from 'hooks/use-effect-once';
import { useLogger } from 'state/logger';

import { ROBOTS_TXT_PATH } from '../constants';
import { getDisallowedUrls } from '../utils';

// Fetch /robots.txt file and parse it to get the disallowed URLs
export const useDisallowedUrls = () => {
  const log = useLogger();
  const [disallowedUrls, setDisallowedUrls] = useState<string[]>([]);

  useEffectOnce(() => {
    const handleRobots = async () => {
      try {
        const response = await fetch(ROBOTS_TXT_PATH);
        const robotsTxt = await response.text();
        const disallowedUrls = getDisallowedUrls(robotsTxt);

        setDisallowedUrls(disallowedUrls);
      } catch (error) {
        log.error(error);
      }
    };

    handleRobots();
  });

  return disallowedUrls;
};
