import React from 'react';

import { ExternalLink, IconContainer } from './styled';
import { ISocialIconProps } from './types';

export const SocialIcon: React.FC<ISocialIconProps> = ({ href, children }) => {
  return (
    <ExternalLink href={href ?? ''}>
      <IconContainer>{children}</IconContainer>
    </ExternalLink>
  );
};
