import React, { FC } from 'react';

import { useFlag } from 'state/launchdarkly';
import { LaunchDarklyFlag } from 'utils/launchdarkly';

import { OrderTrackingBannerContainer } from './order-tracking-banner-container';

export const OrderTrackingBannerContainerMaybe: FC = () => {
  const orderTrackingFlags = {
    enableWaitTimeManagement: useFlag(LaunchDarklyFlag.ENABLE_WAIT_TIME_MANAGEMENT),
    enableAsyncPayment: useFlag(LaunchDarklyFlag.ENABLE_ASYNC_PAYMENT),
  };

  const enableTrackingBanner = Object.keys(orderTrackingFlags).some(
    flag => orderTrackingFlags[flag]
  );

  return enableTrackingBanner ? <OrderTrackingBannerContainer /> : null;
};
