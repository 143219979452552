import React, { FC } from 'react';

import { Box } from '@rbilabs/components-library/build/components/layout';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import Modal from 'components/modal';
import Picture from 'components/picture';
import { useFeatureLayout } from 'hooks/use-feature-layout';
import useMediaQuery from 'hooks/use-media-query';

import { ContentContainer, GeolocationImageContainer, HeaderPrefix, ModalHeader } from './styled';
import { IGeolocationModalViewProps } from './types';

export const GeolocationModalView: FC<IGeolocationModalViewProps> = ({
  handleDismissModal,
  handleGeolocationPrompt,
}) => {
  const isMobile = useMediaQuery('mobile');
  const { formatMessage } = useIntl();
  const { featureLayout } = useFeatureLayout();
  const imageId = featureLayout?.geolocationModalImage?.locale;
  const modalHeading = formatMessage({ id: 'allowLocationServicesToFind' });
  const allowLocationServices = formatMessage({ id: 'allowLocationServices' });

  return (
    <Modal
      onDismiss={handleDismissModal}
      eventData={{
        modalAppearanceEventMessage: 'Allow location services',
      }}
      data-testid="geolocation-modal"
    >
      <ContentContainer>
        <ModalHeader>
          <HeaderPrefix data-testid="modal-heading" id="modal-heading">
            {modalHeading}
          </HeaderPrefix>
          <br />
          {formatMessage({ id: 'ourRestaurantsNearYou' })}
        </ModalHeader>
        <GeolocationImageContainer>
          {!!imageId && <Picture image={imageId} alt="" />}
        </GeolocationImageContainer>
        <Box width="100%" minWidth="auto" center margin="0 0 0.625rem">
          <ActionButton
            onlyIcon={isMobile}
            data-testid="geolocation-allow"
            onClick={handleGeolocationPrompt}
          >
            {allowLocationServices}
          </ActionButton>
        </Box>
      </ContentContainer>
    </Modal>
  );
};
