export default `
<svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14 24.3098C19.5228 24.3098 24 19.8326 24 14.3098C24 8.78691 19.5228 4.30975 14 4.30975C8.47715 4.30975 4 8.78691 4 14.3098C4 19.8326 8.47715 24.3098 14 24.3098Z" fill="white"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.8694 21.5348L8.03192 15.5223C6.95928 14.3937 6.69561 12.7197 7.36942 11.316C7.94174 10.0961 9.16566 9.315 10.5132 9.30975C11.2454 9.3093 11.9581 9.54613 12.5444 9.98475L13.5007 10.6848L13.8694 10.9598L14.2382 10.6848L15.2007 9.98475C15.7851 9.54701 16.4955 9.3102 17.2257 9.30975C18.5668 9.32069 19.7824 10.1011 20.3507 11.316C21.0343 12.7121 20.7857 14.3852 19.7257 15.5223L13.8694 21.5348Z" fill="#838383"/>
<defs>
<filter id="filter0_d" x="0" y="0.309753" width="28" height="28" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="2"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>
`;
