import { useCallback, useRef } from 'react';

import { AnyArgs, VariadicFn } from '@rbi-ctg/frontend';
import { useLogger } from 'state/logger';
import { ILogger } from 'utils/logger';

type Queueable<A extends AnyArgs = any[]> = VariadicFn<A, void>;
export type WithReadyQueue = <A extends AnyArgs = any[]>(fn: Queueable<A>) => Queueable<A>;

interface IUseReadyQueue {
  drainQueue(): void;
  enqueueIfNotDrained: WithReadyQueue;
}

type ReadyQueue = Array<[Queueable, AnyArgs]>;

const tryCalling =
  (callback: Queueable, logger: ILogger) =>
  (...args: AnyArgs) => {
    try {
      callback(...args);
    } catch (error) {
      logger.error(error);
    }
  };

/**
 * @function useReadyQueue
 * useReadyQueue provides helper functions for creating callbacks
 * that should be queued rather than called immediately. These are used
 * to ensure that any asynchronously loaded dependencies are not called
 * before loading.
 *
 * Usage:
 *
 * const { enqueueIfNotDrained, drainQueue } = useReadyQueue();
 *
 * const functionWithGlobalDependency = enqueueIfNotDrained(() => {
 *   window.someSdk.method();
 * });
 *
 * useEffect(() => {
 *   loadDependencyAsynchronously().then(drainQueue);
 * });
 *
 * @returns {IUseReadyQueue} an object containing the above functions
 */
const useReadyQueue = (): IUseReadyQueue => {
  const logger = useLogger();
  const readyQueue = useRef<ReadyQueue>([]);
  const drained = useRef(false);

  const enqueueIfNotDrained: WithReadyQueue = useCallback(
    callback =>
      (...args) => {
        if (drained.current) {
          return tryCalling(callback, logger)(...args);
        }
        readyQueue.current = [...readyQueue.current, [tryCalling(callback, logger), args]];
      },
    [logger]
  );

  const drainQueue = useCallback((): void => {
    // does nothing if already drained
    if (drained.current) {
      return;
    }

    readyQueue.current.forEach(([callback, args]) => {
      callback(...args);
    });

    readyQueue.current = [];
    drained.current = true;
  }, []);

  return { enqueueIfNotDrained, drainQueue };
};

export default useReadyQueue;
