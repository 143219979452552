import styled from 'styled-components';

import { CART_PREVIEW_ITEM_BACKGROUND } from '../constants';

export const CartPreviewItem = styled.div`
  border: 1px solid ${Styles.color.grey.four};
  border-radius: ${Styles.borderRadius};
  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
  grid-column-gap: 0.5em;
  padding: 0.5rem;
  width: 100%;
  min-height: 102px;
  background-color: ${CART_PREVIEW_ITEM_BACKGROUND};
`;
