import styled from 'styled-components';

import {
  Badge as DefaultBadge,
  StyledIncrementor as DefaultStyledIncrementor,
} from './styled.base';

export * from './styled.base';

export const Badge = styled(DefaultBadge)`
  background-color: ${Styles.color.white};
`;

export const StyledIncrementor = styled(DefaultStyledIncrementor)`
  div {
    font-size: 1rem;
  }
`;
