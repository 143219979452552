import { HandlerType, StatusType, datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

import { Loggable } from '@rbi-ctg/frontend';
import LocalStorage from 'utils/cognito/storage';
import { StorageKeys } from 'utils/local-storage';
import * as location from 'utils/location';

import {
  appVersionCode,
  brand,
  country,
  env,
  getConfigValue,
  isLocalDev,
  platform,
} from '../environment';

import { makeBeforeSendCallback } from './make-before-send-callback';
import { normalizeUrl } from './utils';

export { StatusType } from '@datadog/browser-logs';

export interface Context {
  [x: string]: Loggable | Loggable[];
}

interface DataDogLogger {
  context?: Context;
  message: string | Error;
  status: StatusType;
}

export function init(
  context: Context,
  configuredSampleRate?: number,
  ddSampleRateMparticleEvents?: number
): void {
  const handler = isLocalDev ? HandlerType.console : HandlerType.http;
  const defaultRate = isLocalDev ? 100 : 1;
  const sampleRate = typeof configuredSampleRate === 'number' ? configuredSampleRate : defaultRate;
  const sampleRateMParticle =
    typeof ddSampleRateMparticleEvents === 'number' ? ddSampleRateMparticleEvents : sampleRate;
  const site = getConfigValue('datadog').logs.dataCenter ?? 'datadoghq.com';
  const clientToken = getConfigValue('datadog').logs.clientToken;

  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    env: env(),
    service: `${brand()}_${platform()}`,
    version: appVersionCode(),
    beforeSend: makeBeforeSendCallback(sampleRate, sampleRateMParticle),
  });

  datadogLogs.setLoggerGlobalContext({
    ...datadogLogs.getLoggerGlobalContext(),
    ...context,
  });

  datadogLogs.logger.setHandler(handler);
}

const UNKNOWN_ERROR_MESSAGE: string = 'Unknown application error. Unable to parse error message.';

export function dataDogLogger({ message, context = {}, status }: DataDogLogger): void {
  const ctx = {
    appVersionCode: appVersionCode(),
    brand: brand(),
    country,
    platform: platform(),
    stage: env(),
    userId: LocalStorage?.getItem(StorageKeys.USER)?.cognitoId,
    ...context,
  };

  const strMessage: string =
    typeof message === 'string' ? message : message?.message || UNKNOWN_ERROR_MESSAGE;

  datadogLogs.logger.log(strMessage, ctx, status);
}

export function addContext(key: string, value: string) {
  datadogLogs.logger.addContext(key, value);
}

export function initDatadogRum(context: Context, sampleRate: number, replaySampleRate: number) {
  const awsConfig = getConfigValue('aws');
  const dataDogConfig = getConfigValue('datadog');
  const applicationId = dataDogConfig.rum.applicationId;
  const clientToken = dataDogConfig.rum.clientToken;
  const site = dataDogConfig.rum.dataCenter || 'datadoghq.eu';
  const baseUrl = location.get('origin');
  const allowedTracingUrls = [
    awsConfig.gqlApiUrl,
    awsConfig.gqlGatewayApiUrl,
    `${baseUrl}/api/gateway`,
    `${baseUrl}/api/whitelabel`,
  ];

  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service: `${brand()}_${platform()}`,
    env: env(),
    version: appVersionCode(),
    sampleRate: isLocalDev ? 0 : sampleRate,
    sessionReplaySampleRate: replaySampleRate ?? 0,
    trackUserInteractions: true,
    trackResources: true,
    trackInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls,
    beforeSend: event => {
      if (event.type === 'view') {
        event.view.url = normalizeUrl(event.view.url);
      }
      return true;
    },
  });

  datadogRum.setGlobalContext({
    ...datadogRum.getGlobalContext(),
    ...context,
    appVersionCode: appVersionCode(),
    brand: brand(),
    platform: platform(),
    stage: env(),
    country,
  });

  datadogRum.startSessionReplayRecording();
}
