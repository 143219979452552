import React from 'react';

const IconMobileNavLogo = props => (
  <svg data-dir="ltr" viewBox="0 0 21 24" {...props}>
    <title>Mobile Nav Logo</title>
    <path
      d="M0 8.146l2.063 2.49s1.103-1.621 2.463-.534c1.045.836.726 2.344.466 3.584-.078.374-.153.724-.18 1.023-.16 1.636.243 2.96.902 4.011.838 1.338 2.783 2.45 4.871 2.844.16.03.313.052.465.071l.217 2.302h1.574l.212-2.248c3.61-.291 8.72-3.824 6.446-9.773-1.046-2.737-3.262-6.824-3.262-6.824l.026-.007c.877-.223 1.046-1.361.022-1.972l.732-.678-1.07-.44.4-1.463-2.3.91.002-.002.003-.003S13.47.222 12.582.212c-.746-.008-1.099.532-1.099.532s-.199-.556-.71-.707c-.51-.152-1.344.146-1.45 1.073-.119 1.04.722 1.575.998 1.663l.079.026c.323.108.776.26 1.653-.111l-.004 2.128-.004 2.15c0 .206.001.43.004.668.016 1.42.034 3.291-.495 4.377-.62 1.268-1.324 1.795-2.257 1.686-1.043-.122-1.4-1.099-1.037-2.911.362-1.812.668-3.615-.852-4.959C5.888 4.484 3.155 4.337 0 8.146zm14.246-5.819c.234-.12.534-.003.67.26.134.264.052.575-.182.694-.234.12-.535.003-.67-.26-.134-.263-.054-.575.182-.694zm-.135 21.046h.19v.565h.086v-.565h.191v-.082h-.466v.082h-.001zm.67-.08l.201.463.201-.463h.112v.647h-.082v-.52l-.19.431h-.081l-.19-.43v.519h-.083v-.647h.111z"
      fillRule="evenodd"
      clipRule="evenodd"
    />
  </svg>
);

export default IconMobileNavLogo;
