import * as React from 'react';
import { useEffect } from 'react';

import { useGetMeQuery } from 'generated/rbi-graphql';
import { useShortCodeRefresh } from 'hooks/loyalty/use-short-code-refresh';
import { useForceStoreSelectionFlagged } from 'hooks/use-force-store-selection';
import useMaximumScreenBrightness from 'hooks/use-maximum-screen-brightness';
import { useRedeemInRestaurantConfig } from 'hooks/use-redeem-in-restaurant-config';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import {
  ShortCodeState,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';

import LoyaltyInRestaurantRedemptionSignupV3 from '../loyalty-in-restaurant-redemption-signup-v3';
import { Widget, useWidgetComponents } from '../loyalty-widget-components';
import LoyaltyLegacyShortCodeWidget from '../loyalty-widgets/loyalty-legacy-short-code-widget/loyalty-legacy-short-code-widget';
import { LoyaltyMarketingTileGroupWidget } from '../loyalty-widgets/loyalty-marketing-tile-widget';
import { LoyaltyQRAndShortCodeWidget } from '../loyalty-widgets/loyalty-qr-and-short-code-widget';
import { ILoyaltyQrWidgetProps } from '../loyalty-widgets/loyalty-qr-widget/loyalty-qr-widget';
import { LoyaltyQrWidgetV3 } from '../loyalty-widgets/loyalty-qr-widget/loyalty-qr-widget-new-ui.styled';
import LoyaltyShortCodeWidget from '../loyalty-widgets/loyalty-short-code-widget/loyalty-short-code-widget';
import { LoyaltyTextWidgetV3 } from '../loyalty-widgets/loyalty-text-widget/loyalty-text-widget-new-ui.styled';

import LoyaltyInRestaurantRedemptionView from './loyalty-in-restaurant-redemption.view';
import { LoyaltyInRestaurantRedemptionWidgets } from './types';
import { useFeaturesInRestaurantRedemption } from './use-feature-loyalty-in-restaurant-redemption';

export const componentWidgetMap: Record<LoyaltyInRestaurantRedemptionWidgets, Widget> = {
  [LoyaltyInRestaurantRedemptionWidgets.QrCodeWidget]: (props: ILoyaltyQrWidgetProps) => (
    <LoyaltyQrWidgetV3 {...props} />
  ),
  [LoyaltyInRestaurantRedemptionWidgets.QrAndShortCode]: LoyaltyQRAndShortCodeWidget,
  [LoyaltyInRestaurantRedemptionWidgets.ShortCodeWidget]: LoyaltyShortCodeWidget,
  [LoyaltyInRestaurantRedemptionWidgets.Text]: LoyaltyTextWidgetV3,
  [LoyaltyInRestaurantRedemptionWidgets.MarketingTiles]: LoyaltyMarketingTileGroupWidget,
  [LoyaltyInRestaurantRedemptionWidgets.LegacyCodeWidget]: LoyaltyLegacyShortCodeWidget,
};

const LoyaltyInRestaurantRedemption = () => {
  const skipLoyaltyStoreValidation = useFlag(LaunchDarklyFlag.SKIP_LOYALTY_STORE_VALIDATION);
  const enableUserBlocking = useFlag(LaunchDarklyFlag.ENABLE_USER_BLOCKING);
  const { isAuthenticated, user } = useAuthContext();

  const {
    inRestaurantLoyaltyEnabledAtRestaurantLoading,
    inRestaurantLoyaltyEnabledAtRestaurant,
    generateShortCode,
    shortCode,
    shortCodeState,
    shortCodeLoading,
  } = useInRestaurantRedemptionContext();

  useShortCodeRefresh();

  useForceStoreSelectionFlagged();
  const { redeemInRestaurantConfig, redeemInRestaurantConfigLoading } =
    useRedeemInRestaurantConfig();

  // Calling getMe is necessary to prevent blocked users from generating a
  // short code since the loyalty service cannot make this validation due to
  // latency constraints. Ref: https://rbictg.atlassian.net/wiki/spaces/EGMT/pages/4842815640/2024-07-02+Terminate+blocked+users+active+sessions+in+WL-APP#createOrderSelectionByType
  const { loading: userLoading } = useGetMeQuery({
    skip: !enableUserBlocking || !user,
    fetchPolicy: 'no-cache',
  });

  // TODO: Remove this hook after all markets are migrated to new config
  const { data: legacyConfig } = useFeaturesInRestaurantRedemption({
    // Only query the legacy config if new configuration is not found
    skip: redeemInRestaurantConfigLoading || !!redeemInRestaurantConfig,
  });

  const config = redeemInRestaurantConfig
    ? redeemInRestaurantConfig.widgets
    : [...(legacyConfig?.topWidgets || []), ...(legacyConfig?.bottomWidgets || [])];

  const widgets = useWidgetComponents(componentWidgetMap, config);

  const { loyaltyUser, loading: loyaltyLoading } = useLoyaltyUser();
  const loyaltyUserId = loyaltyUser?.id;
  const redemptionNotEnabled =
    !skipLoyaltyStoreValidation &&
    !inRestaurantLoyaltyEnabledAtRestaurantLoading &&
    !inRestaurantLoyaltyEnabledAtRestaurant;

  useMaximumScreenBrightness();

  useEffect(() => {
    if (
      loyaltyUserId &&
      !redemptionNotEnabled &&
      !shortCode &&
      !shortCodeLoading &&
      shortCodeState === ShortCodeState.None
    ) {
      generateShortCode();
    }
  }, [
    generateShortCode,
    inRestaurantLoyaltyEnabledAtRestaurantLoading,
    loyaltyUserId,
    redemptionNotEnabled,
    shortCode,
    shortCodeLoading,
    shortCodeState,
  ]);

  if (!isAuthenticated()) {
    return <LoyaltyInRestaurantRedemptionSignupV3 />;
  }

  return (redeemInRestaurantConfig || legacyConfig) &&
    !redeemInRestaurantConfigLoading &&
    !inRestaurantLoyaltyEnabledAtRestaurantLoading &&
    !userLoading &&
    !loyaltyLoading ? (
    <LoyaltyInRestaurantRedemptionView widgets={widgets} />
  ) : null;
};

export default LoyaltyInRestaurantRedemption;
