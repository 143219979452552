export const OBJECT_FIT_CONTAIN = false;

export const QR_CODE_TEST_ID = 'redemption-qr-code';
export const QR_CODE_SELECTOR = `[data-testid="${QR_CODE_TEST_ID}"]`;

export const SHORT_CODE_TEST_ID = 'redemption-short-code';
export const SHORT_CODE_SELECTOR = `[data-testid="${SHORT_CODE_TEST_ID}"]`;

export const CONFIRM_MODAL_TEST_ID = 'offer-confirm-close-modal';
export const CONFIRM_MODAL_SELECTOR = `[data-testid="${CONFIRM_MODAL_TEST_ID}"]`;

export const REDEMPTION_TITLE_TEST_ID = 'redemption-title';
