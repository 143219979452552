import styled from 'styled-components';

import {
  InRestaurantRedemptionEntryContainer as InRestaurantRedemptionEntryContainerBase,
  PictureAndDetails as PictureAndDetailsBase,
  StyledIconX as StyledIconXBase,
} from './reward-redemption.styled.base';

export * from './reward-redemption.styled.base';

export const InRestaurantRedemptionEntryContainer = styled(
  InRestaurantRedemptionEntryContainerBase
)`
  background-color: ${Styles.color.white};
`;

export const PictureAndDetails = styled(PictureAndDetailsBase)`
  flex: 1;
  flex-direction: row-reverse;
  align-items: center;
`;

export const StyledIconX = styled(StyledIconXBase)`
  max-width: 0.75rem;
  max-height: 0.75rem;
  margin: 0 0.5rem;
`;
