import { useCallback, useMemo } from 'react';

import { WatchQueryFetchPolicy } from '@apollo/client';

import { useLoyaltyUserRewardsQuery } from 'generated/graphql-gateway';
import { useLoyaltyRewardsQuery } from 'generated/sanity-graphql';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { IEngineRewardsMap, ISanityRewardsMap } from 'state/loyalty/types';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';

interface IParsedEngineRewards {
  engineRewardIds: string[];
  engineRewardsMap: IEngineRewardsMap;
}

interface IUseLoyaltyRewardsList {
  fetchPolicy?: WatchQueryFetchPolicy;
  skip?: boolean;
}

export const useLoyaltyRewardsList = ({
  fetchPolicy = 'cache-first',
  skip = false,
}: IUseLoyaltyRewardsList = {}) => {
  const { serviceMode } = useServiceModeContext();
  const { store } = useStoreContext();

  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();

  // To Do: move this logic to loyalty GQL server in order to make 1 call on the frontend
  // and to avoid creating rewards map
  const {
    data: engineResponse,
    loading: engineRewardsLoading,
    refetch: refetchEngineRewards,
  } = useLoyaltyUserRewardsQuery({
    skip: !loyaltyEnabled || !loyaltyUser?.id || skip,
    variables: {
      loyaltyId: loyaltyUser?.id || '',
      where: {
        ignorePointBalance: true,
        serviceMode: serviceMode || undefined,
        storeId: store?.number,
      },
    },
    fetchPolicy,
  });

  const { engineRewardIds, engineRewardsMap } = useMemo(
    () =>
      (engineResponse?.loyaltyUser?.rewards || []).reduce(
        (acc: IParsedEngineRewards, reward) => {
          // Extract engineIds to query for rewards in sanity
          if (reward?.id) {
            acc.engineRewardIds.push(reward.id);
            acc.engineRewardsMap[reward.id] = reward;
          }
          return acc;
        },
        { engineRewardIds: [], engineRewardsMap: {} }
      ),
    [engineResponse]
  );

  const {
    data,
    loading: sanityRewardsLoading,
    refetch: refetchCmsRewards,
  } = useLoyaltyRewardsQuery({
    skip: !engineRewardIds?.length || skip,
  });

  const refetchRewards = useCallback(
    async (loyaltyId: string) => {
      await refetchEngineRewards({
        loyaltyId,
        where: {
          ignorePointBalance: true,
          serviceMode: serviceMode || undefined,
          storeId: store?.number,
        },
      });
      await refetchCmsRewards();
    },
    [refetchCmsRewards, refetchEngineRewards, serviceMode, store]
  );

  const rewardsByEngineId = useMemo(
    () => data?.allReward.filter(reward => engineRewardsMap[reward?.loyaltyEngineId || '']),
    [data, engineRewardsMap]
  );

  const sanityRewardsMap = useMemo(() => {
    if (!rewardsByEngineId) {
      return null;
    }
    return rewardsByEngineId.reduce((acc: ISanityRewardsMap, reward) => {
      acc[reward._id] = reward;
      return acc;
    }, {});
  }, [rewardsByEngineId]);

  const rewardsLoading = engineRewardsLoading || sanityRewardsLoading;

  return {
    rewards: rewardsByEngineId || null,
    loading: rewardsLoading,
    engineRewardsMap,
    sanityRewardsMap,
    refetchRewards,
  };
};
