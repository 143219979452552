import { useUserGeoQuery } from 'generated/rbi-graphql';
import { useLDContext } from 'state/launchdarkly';

export const useGeoData = () => {
  const { updateUserLiveLocation } = useLDContext();
  const { data, loading } = useUserGeoQuery({
    onCompleted({ userGeo }) {
      updateUserLiveLocation(userGeo);
    },
  });

  return { userGeoData: data, loading };
};
