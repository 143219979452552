import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { noop } from 'lodash';
import isEqual from 'react-fast-compare';

import { IBaseProps, ILocation } from '@rbi-ctg/frontend';
import { MapProviders } from 'hooks/geolocation/enum';
import usePlaceIdDetails from 'hooks/geolocation/use-place-id-details';
import { usePlaceIdTomTomDetails } from 'hooks/geolocation/use-place-id-tomtom-details';
import useDebouncedEffectOnUpdates from 'hooks/use-debounce-effect-on-updates';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import { OnAppStateChange, useUpdateOnAppStateChange } from 'hooks/use-update-on-app-state-change';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLogger } from 'state/logger';
import { useStoreContext } from 'state/store';
import {
  GeolocationPermissionStates,
  PermissionState,
  checkIfLocationPermissionIsPreapproved,
  determinePermissionsFromGetCurrentPositionErrorCode,
  getCoordinateDetails,
  getUsersCurrentPosition,
  isKnownPermissionState,
  promptUserForLocationPermission,
} from 'utils/geolocation';

export enum CoordinateTypes {
  USER,
  MANUAL,
}

export interface ISetCoordinatesOptions {
  newCoords: ILocation;
  coordType: CoordinateTypes;
}

export type ISetCoordinatesManualOptions = Omit<ISetCoordinatesOptions, 'coordType'>;

export interface IGeolocationCtx {
  loadCurrentPosition: VoidFunction;
  loadingUserCoordinates: boolean;
  manualAddress: string;
  manualPlaceId: string;

  updateManualCoordinates(address: string, placeId?: string | undefined): void;

  coordinatesAvailable: boolean;
  activeCoordinates: ILocation | null;
  isPermissionPrompt: boolean;
  isPermissionChecking: boolean;
  isPermissionGranted: boolean;
  isPermissionDenied: boolean;
  isPermissionKnown: boolean;
  userCoordinates: ILocation | null;
  promptForLocation: VoidFunction;
  setCoordinatesManual: (options: ISetCoordinatesManualOptions) => void;
  setManualAddress: (address: string) => void;
  setManualPlaceId: (placeId: string) => void;
  setPermissionPrompt: VoidFunction;
  setPermissionChecking: VoidFunction;
  setPermissionDenied: VoidFunction;
  resetCoordinatesUser: VoidFunction;
  resetCoordinatesManual: VoidFunction;
  resetCoordsAndLoadCurrentPosition: VoidFunction;
}

export const GeolocationContext = createContext<IGeolocationCtx>({
  loadCurrentPosition: noop as any,
  loadingUserCoordinates: false,
  manualAddress: '',
  manualPlaceId: '',
  updateManualCoordinates: noop,
  coordinatesAvailable: false,
  activeCoordinates: null,
  isPermissionPrompt: true,
  isPermissionChecking: false,
  isPermissionGranted: false,
  isPermissionDenied: false,
  isPermissionKnown: false,
  userCoordinates: null,
  setCoordinatesManual: noop,
  setManualAddress: noop,
  setManualPlaceId: noop,
  setPermissionPrompt: noop,
  setPermissionChecking: noop,
  setPermissionDenied: noop,
  resetCoordinatesUser: noop,
  resetCoordinatesManual: noop,
  resetCoordsAndLoadCurrentPosition: noop,
  promptForLocation: noop,
});

export const useGeolocation = () => useContext(GeolocationContext);

export const GeolocationProvider = ({ children }: IBaseProps) => {
  const { addressProvider } = useStoreContext();
  const { trackEvent, updateUserLocationPermissionStatus } = useCdpContext();
  const enablePersistAddressOnStoreSelector = useFlag(
    LaunchDarklyFlag.ENABLE_PERSIST_ADDRESS_ON_STORE_SELECTOR
  );
  const logger = useLogger();

  const [permission, setPermissionState] = useState<GeolocationPermissionStates>(
    GeolocationPermissionStates.PROMPT
  );

  const [tryLoadingUserCoordinates, setTryLoadingUserCoordinates] = useState(false);
  const [errorGettingUserCoordinates, setErrorGettingUserCoordinates] =
    useState<GeolocationPermissionStates | null>(null);
  const [userCoordinates, setUserCoordinates] = useState<ILocation | null>(null);

  const [manualAddress, setManualAddress] = useState('');
  const [manualPlaceId, setManualPlaceId] = useState('');
  const [manualCoordinates, setManualCoordinates] = useState<ILocation | null>(null);

  /**
   * resetCoordinates consolidates coordinates resetting logic
   *  for both user coordinates and manually searched ones
   * resetting manual coordinates also resets the manual
   *  address string to empty which can clear search input
   *  so as not to confuse the user on which coords are being
   *  used
   */
  const resetCoordinates = useCallback((coordType: CoordinateTypes) => {
    switch (coordType) {
      case CoordinateTypes.USER:
        setUserCoordinates(null);
        break;
      case CoordinateTypes.MANUAL:
        setManualAddress('');
        setManualCoordinates(null);
        break;
      default:
    }
  }, []);

  /**
   * setCoordinates consolidates coordinates setting logic
   *  for both user coordinates and manually searched ones
   * only updates if those coordinates aren't already set
   *  to the specified value
   * for user coordinates, we also deal with permission
   *  setting, as needed
   */
  const setCoordinates = useCallback(
    ({ newCoords, coordType }: ISetCoordinatesOptions) => {
      switch (coordType) {
        case CoordinateTypes.USER:
          if (!isEqual(userCoordinates, newCoords)) {
            setUserCoordinates(newCoords);
          }
          break;
        case CoordinateTypes.MANUAL:
          if (!isEqual(manualCoordinates, newCoords)) {
            setManualCoordinates(newCoords);
          }
          break;
        default:
      }
    },
    [manualCoordinates, userCoordinates]
  );

  /**
   * getDetails and updateManualCoordinates deal with manual address search
   *  when invoked, handles empty address passed by triggering
   *    resetCoordinates
   *  otherwise, sets manual address and, if valid placeId is passed,
   *  handles triggering setCoordinates for manual coordinates update
   */
  const getDetails = usePlaceIdDetails();
  const { getTomTomDetails } = usePlaceIdTomTomDetails();

  const updateManualCoordinates = useCallback(
    (address: string, placeId?: string) => {
      // handle no manual address selected
      if (!address) {
        resetCoordinates(CoordinateTypes.MANUAL);
        return;
      }

      setManualAddress(address);
      if (enablePersistAddressOnStoreSelector && placeId) {
        setManualPlaceId(placeId);
      }
      if (addressProvider === MapProviders.TOMTOM) {
        const placeData = getTomTomDetails(placeId);
        if (placeData) {
          setCoordinates({ newCoords: placeData.coordinates, coordType: CoordinateTypes.MANUAL });
        }
      } else {
        getDetails(placeId).then(results => {
          if (!results) {
            return;
          }
          const { geometry } = results;
          const location = {
            lat: geometry!.location.lat(),
            lng: geometry!.location.lng(),
          };

          setCoordinates({ newCoords: location, coordType: CoordinateTypes.MANUAL });
        });
      }
    },
    [
      addressProvider,
      enablePersistAddressOnStoreSelector,
      getDetails,
      getTomTomDetails,
      resetCoordinates,
      setCoordinates,
    ]
  );

  /**
   * loadCurrentPosition handles determining a user's current
   *  coordinates (provided they have granted location permission)
   *  and triggers setCoordinates for user coords if some are found
   * if not, deals with error handling and updating of permissions
   */
  const loadCurrentPosition = useCallback(() => {
    return getUsersCurrentPosition()
      .then(location => {
        setManualAddress('');
        getCoordinateDetails(location).then(geocodeResult => {
          if (geocodeResult) {
            updateManualCoordinates(geocodeResult.formatted_address, geocodeResult.place_id);
          }
        });

        if (!location || !location.lat || !location.lng) {
          return Promise.reject({ code: PermissionState.DENIED });
        }

        setCoordinates({
          newCoords: location.accuracy
            ? {
                lat: location.lat,
                lng: location.lng,
                accuracy: location.accuracy,
              }
            : {
                lat: location.lat,
                lng: location.lng,
              },
          coordType: CoordinateTypes.USER,
        });
        return location;
      })
      .catch(error => {
        logger.error({ error, message: 'Error geolocating user' });
        if (error?.code) {
          setErrorGettingUserCoordinates(
            determinePermissionsFromGetCurrentPositionErrorCode(error.code)
          );
          return undefined;
        }
        // get the default permissions since we don't have a defined error code
        setErrorGettingUserCoordinates(determinePermissionsFromGetCurrentPositionErrorCode());
        throw error;
      })
      .finally(() => {
        setTryLoadingUserCoordinates(false);
      });
  }, [setCoordinates, logger, updateManualCoordinates]);

  /**
   * set state to load current position and use debounced effect so that
   * we don't trigger multiple times while waiting for the initial call
   */
  const tryLoadingCurrentPosition = useCallback(() => {
    if (!tryLoadingUserCoordinates && permission === GeolocationPermissionStates.GRANTED) {
      setTryLoadingUserCoordinates(true);
    }
  }, [tryLoadingUserCoordinates, permission]);

  /**
   * tryLoadingCurrentPosition sets tryLoadingUserCoordinates to true
   * if it isn't already AND if we have granted geolocation permissions
   * then, based on the debounce timeout, this effect may trigger actually
   * loading the user's current location
   * this helps prevent multiple expensive geolocation calls in a short period
   * of time when it is highly unlikely a user's location has actually changed
   */
  useDebouncedEffectOnUpdates(
    () => {
      if (tryLoadingUserCoordinates) {
        loadCurrentPosition();
      }
    },
    1000,
    [loadCurrentPosition, tryLoadingUserCoordinates]
  );

  /**
   * setPermission protects state updates from occurring if permissions
   *  haven't actually changed
   */
  const setPermission = useCallback(
    (permissionState: GeolocationPermissionStates) => {
      updateUserLocationPermissionStatus();
      if (permissionState !== permission) {
        setPermissionState(permissionState);
      }
    },
    [permission, updateUserLocationPermissionStatus]
  );

  /**
   * handleCheckingPermissions kicks off functionality
   *  to get users's acutal permissions. it WILL trigger
   *  the native/browser prompt if no preapproved permissions
   *  are found
   */
  const handleCheckingPermissions = useCallback(async () => {
    try {
      const preapprovedPermissions = await checkIfLocationPermissionIsPreapproved(false);
      // if preapproved permissions are KNOWN, then set them
      if (isKnownPermissionState(preapprovedPermissions)) {
        setPermission(preapprovedPermissions);
      } else {
        // otherwise we want to trigger the prompt
        const promptResponse = await promptUserForLocationPermission();
        setPermission(promptResponse);
      }
    } catch (e) {
      // NOTE: should only be triggered on native bc that's the only time we
      //       reject without a GeolocationPermissionState
      // see utils/geolocation/index.app.ts -> promptUserForLocationPermission
      // in this case, just set as denied because something has gone wrong
      setPermission(GeolocationPermissionStates.DENIED);
    }
  }, [setPermission]);

  /**
   * on updates, check permissions to see if there's something new to do
   */
  useEffectOnUpdates(() => {
    // if we have denied permissions and we have user coordinates, remove them so
    // we can trigger the location services modal
    if (userCoordinates && permission === GeolocationPermissionStates.DENIED) {
      resetCoordinates(CoordinateTypes.USER);
      return;
    }
    // if we have granted permissions but no current coordinates, try getting some
    if (!userCoordinates && permission === GeolocationPermissionStates.GRANTED) {
      tryLoadingCurrentPosition();
      return;
    }
    // if permissions updated to CHECKING, kickoff functionality to actually facilitate checking permissions
    if (permission === GeolocationPermissionStates.CHECKING) {
      handleCheckingPermissions();
      return;
    }
  }, [handleCheckingPermissions, permission]);

  /**
   * attempting to decouple loading user's current position from
   *  the permission state. to do this, i need a way to NOT call
   *  set permission directly from loadCurrentPostition so using
   *  this error state to trigger an effect that will call permission
   *  to be set (if different from current permission state)
   */
  useEffect(() => {
    if (errorGettingUserCoordinates) {
      setPermission(errorGettingUserCoordinates);
      setErrorGettingUserCoordinates(null);
    }
  }, [errorGettingUserCoordinates, permission, setPermission]);

  /**
   * softCheckForPermissions does soft check for permissions
   *  (meaning checks if we can get permission state without prompting user)
   *  invokes function to set found permissions which may trigger additional
   *  functionality based on updated permissions
   */
  const softCheckForPermissions = useCallback(async () => {
    const returnedPermissions = await checkIfLocationPermissionIsPreapproved(false);
    setPermission(returnedPermissions);
  }, [setPermission]);

  /**
   * trigger initial check to see if we have permission
   *  to get user's coordinates on inital app load
   */
  useEffectOnce(() => {
    softCheckForPermissions();
  });

  /**
   * maybeSoftCheckForPermission
   *  get user's current location permissions when app becomes active
   *  this is useful for native when app goes in background and then
   *  becomes reactive (i.e. if user goes to permissions and changes)
   */
  const maybeSoftCheckForPermission: OnAppStateChange = useCallback(
    async ({ isActive }) => {
      if (isActive) {
        softCheckForPermissions();
      }
    },
    [softCheckForPermissions]
  );
  useUpdateOnAppStateChange(maybeSoftCheckForPermission);

  /**
   * activeCoordinates
   *  - prioritizes manual coordinates > user coordinates
   *  - only updates if current coordinates don't match
   */
  const activeCoordinates = useMemo(() => {
    if (manualCoordinates) {
      return manualCoordinates;
    }
    if (userCoordinates) {
      return userCoordinates;
    }
    return null;
  }, [manualCoordinates, userCoordinates]);

  // enable quick check for whether or not we have coords
  const coordinatesAvailable = !!activeCoordinates;

  /**
   * create consts to use for determining permissions
   * so that consumer don't need to also import types
   */
  const {
    isPermissionPrompt,
    isPermissionChecking,
    isPermissionGranted,
    isPermissionDenied,
    isPermissionKnown,
  } = useMemo(() => {
    return {
      isPermissionPrompt: permission === GeolocationPermissionStates.PROMPT,
      isPermissionChecking: permission === GeolocationPermissionStates.CHECKING,
      isPermissionGranted: permission === GeolocationPermissionStates.GRANTED,
      isPermissionDenied: permission === GeolocationPermissionStates.DENIED,
      isPermissionKnown:
        permission === GeolocationPermissionStates.GRANTED ||
        permission === GeolocationPermissionStates.DENIED,
    };
  }, [permission]);

  /**
   * create funcs to use for setting specific permissions
   * so that consumers don't need to also import types
   * @NOTE: there is purposely no helper for setting
   *        permission as GRANTED because the only way
   *        that permission level should be set is by
   *        first setting permission to CHECKING which
   *        will trigger proper prompting and permission
   *        checking via browser / native
   */
  const setPermissionPrompt = useCallback(() => {
    setPermission(GeolocationPermissionStates.PROMPT);
  }, [setPermission]);

  const setPermissionChecking = useCallback(() => {
    setPermission(GeolocationPermissionStates.CHECKING);
  }, [setPermission]);

  const setPermissionDenied = useCallback(() => {
    setPermission(GeolocationPermissionStates.DENIED);
  }, [setPermission]);

  /**
   * create funcs for resetting specific coords (user / manual)
   * so that consumers don't need to also import types
   */
  const resetCoordinatesUser = useCallback(() => {
    resetCoordinates(CoordinateTypes.USER);
  }, [resetCoordinates]);

  const resetCoordinatesManual = useCallback(() => {
    resetCoordinates(CoordinateTypes.MANUAL);
  }, [resetCoordinates]);

  const setCoordinatesManual = useCallback(
    (options: ISetCoordinatesManualOptions) => {
      setCoordinates({ ...options, coordType: CoordinateTypes.MANUAL });
    },
    [setCoordinates]
  );

  const resetCoordsAndLoadCurrentPosition = useCallback(() => {
    resetCoordinatesUser();
    // since we're now using current position, reset
    // manual search values to avoid user confusion
    resetCoordinatesManual();
    // by loading current position, coords will change
    // and trigger resetting the marker on the map and
    // / or list of restaurants in the list view
    loadCurrentPosition();
  }, [resetCoordinatesUser, resetCoordinatesManual, loadCurrentPosition]);

  const trackGrantLocationPermission = useCallback(() => {
    trackEvent({
      name: CustomEventNames.GRANT_LOCATION_PERMISSION,
      type: EventTypes.Other,
    });
  }, [trackEvent]);

  const promptForLocation = useCallback(() => {
    promptUserForLocationPermission(trackGrantLocationPermission).then(setPermission);
  }, [trackGrantLocationPermission, setPermission]);

  return (
    <GeolocationContext.Provider
      value={{
        // @TODO: ideally rename loadCurrentPosition export
        // to be more clear to consumers since it no longer
        // ALWAYS actually triggers loading coords
        loadCurrentPosition: tryLoadingCurrentPosition,
        loadingUserCoordinates: tryLoadingUserCoordinates,
        updateManualCoordinates,
        manualAddress,
        manualPlaceId,
        coordinatesAvailable,
        activeCoordinates,
        isPermissionPrompt,
        isPermissionChecking,
        isPermissionGranted,
        isPermissionDenied,
        isPermissionKnown,
        userCoordinates,
        setCoordinatesManual,
        setManualAddress,
        setManualPlaceId,
        setPermissionChecking,
        setPermissionDenied,
        setPermissionPrompt,
        resetCoordinatesUser,
        resetCoordinatesManual,
        resetCoordsAndLoadCurrentPosition,
        promptForLocation,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
};
