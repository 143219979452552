import styled from 'styled-components';

export const HorizontalLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0.5rem 0;

  /* Counters margin added to child elements for spacing when wrapped */
  margin-block-end: -1rem;

  > * {
    padding: 0 0.5rem;
    margin-block-end: 1rem;
    border-inline-end: 1px solid ${Styles.color.grey.one};
  }

  > *:last-child {
    border-inline-end: none;
  }
`;
