import styled from 'styled-components';

import { PointsApplied as PointsStyled } from './redeem-reward.styled.default';

export * from './redeem-reward.styled.default';

export const PointsApplied = styled(PointsStyled)`
  font-family: ${Styles.fontFamily.brand};
`;

export const RewardLockContainer = styled.div`
  width: 0.875rem;

  svg {
    position: static;
    margin-block-start: 0.3rem;
  }
`;
