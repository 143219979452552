import styled, { css } from 'styled-components';

import { MarketingBasicTile } from 'components/marketing-basic-tile';
import { BasicTileImage, BasicTileTitle } from 'components/marketing-basic-tile/styled';

const smallTextLeftAligned = css`
  font-size: 0.875rem;
  line-height: 0.875rem;
  text-align: start;
`;

export const Section = styled.section`
  display: flex;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  margin: 1rem 0;

  ${Styles.desktop} {
    padding: 0;
    margin-block-start: 0;
    margin-block-end: 1rem;
    margin-inline: auto;
  }
`;

export const LeftContainer = styled.div`
  ${Styles.desktop} {
    display: flex;
    flex: 1;
    padding-inline-end: 0.5rem;
  }
`;

export const RightContainer = styled.div`
  ${Styles.desktop} {
    display: flex;
    flex: 1;
    padding-inline-start: 0.5rem;
  }
`;

export const QuadTileContainer = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  ${Styles.desktop} {
    max-width: 50rem;
    grid-gap: 1rem;
  }
`;

export const StyledBasicTile = styled(MarketingBasicTile)`
  background: ${Styles.color.alert.info.text};
  flex-direction: row;
  padding: 1rem;

  ${BasicTileTitle} {
    color: ${Styles.color.alert.info.background};
    ${smallTextLeftAligned}
  }

  p {
    color: ${Styles.color.black};
    font-family: ${Styles.fontFamily.brand};
    ${smallTextLeftAligned}
  }

  ${BasicTileImage} {
    margin-block: 0;
    margin-inline-start: 0;
    margin-inline-end: 1rem;
    max-height: 3.125rem;
    max-width: 3.125rem;
  }
`;
