import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { differenceInMinutes } from 'date-fns';
import { cloneDeep, differenceBy, intersectionBy, isEmpty, isEqual, noop } from 'lodash';
import { useIntl } from 'react-intl';
import uuidv4 from 'uuid/v4';

import { UpsellModal } from 'components/upsell-modal';
import { parsePhoneNumberAndCountryCode } from 'components/user-info-form/utils';
import { OfferDiscountTypes } from 'enums/menu';
import { useLoyaltyUserTransactionsQuery } from 'generated/graphql-gateway';
import {
  FlavorFlowType,
  GetOrderDocument,
  OfferType,
  RbiOrderStatus,
  useCancelOrderMutation,
  useGetUserOrdersLazyQuery,
  usePriceOrderMutation,
  usePutFlavorFlowEventsMutation,
  useUpdateOrderMutation,
} from 'generated/rbi-graphql';
import usePosVendor from 'hooks/menu/use-pos-vendor';
import useDialogModal from 'hooks/use-dialog-modal';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { useImagesByChannels } from 'hooks/use-images-by-channels';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useSetResetCartTimeout } from 'hooks/use-set-reset-cart-timeout';
import { useAuthContext } from 'state/auth';
import { useAuthGuestContext } from 'state/auth-guest';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { useErrorContext } from 'state/errors';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import {
  removeAppliedOffersInStorage,
  removeSelectedOfferInStorage,
} from 'state/global-state/models/loyalty/offers/offers.utils';
import { removeAppliedRewardsInStorage } from 'state/global-state/models/loyalty/rewards/rewards.utils';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  EnablePremiumComboSlotsVariations,
  UpsellModalVariations,
} from 'state/launchdarkly/variations';
import { useLocationContext } from 'state/location';
import { useLoggerContext } from 'state/logger/context';
import { useLoyaltyContext } from 'state/loyalty';
import { useIsLoyaltyEnabled } from 'state/loyalty/hooks/use-is-loyalty-enabled';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { getCmsOffersMapByCmsId } from 'state/loyalty/utils';
import { useMainMenuContext } from 'state/main-menu';
import { useMenuContext } from 'state/menu';
import { useCartTip } from 'state/order/hooks/use-cart-tip';
import { usePaymentContext } from 'state/payment';
import { useServiceModeContext } from 'state/service-mode';
import { ServiceMode } from 'state/service-mode/types';
import { useStoreContext } from 'state/store';
import { usePosDataQuery } from 'state/store/hooks/use-pos-data-query';
import { useUIContext } from 'state/ui';
import { getUnavailableCartEntries } from 'utils/availability';
import {
  CartEntryType,
  buildPriceDeliveryInput,
  buildStoreAddress,
  createCartEntry,
  remappedCartForBackEnd,
} from 'utils/cart';
import {
  computeCartTotal,
  computeOtherDiscountAmount,
  computeTotalWithoutOffers,
} from 'utils/cart/helper';
import { PHONE_NUMBER_DUMMY } from 'utils/constants';
import * as DatadogLogger from 'utils/datadog';
import { brand, platform } from 'utils/environment';
import { ISOs, getCountryAndCurrencyCodes } from 'utils/form/constants';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { routes } from 'utils/routing';
import { isCatering as isCateringOrder } from 'utils/service-mode';
import { Measures, PerformanceMarks, getMeasureAndClearMarks, setMark } from 'utils/timing';
import { toast } from 'utils/toast';
import { getOptionsFromParams } from 'utils/wizard';

import { TipAmounts } from './constants';
import { checkLimitReachedEvent, checkMinimumNotReachedEvent } from './custom-events';
import useAlertOrderCateringMinimum from './hooks/use-alert-order-catering-min';
import useAlertOrderDeliveryMinimum from './hooks/use-alert-order-delivery-min';
import useAlertOrderLimit from './hooks/use-alert-order-limit';
import { useHandleReorder } from './hooks/use-handle-reorder';
import useRewardDiscount from './hooks/use-reward-discount';
import { useUnavailableCartEntries } from './hooks/use-unavailable-cart-entries';
import { orderPollFailure, orderPollSuccessful } from './order-state-utils';
import { DeliveryStatus, OrderStatus } from './types';
import {
  determineLimit,
  filterAdditionDetails,
  findEntriesByCmsId,
  replaceEntry,
  replaceEntryArrayItem,
  validateCartEntries,
  validateCartRewards,
} from './utils';

export { DeliveryStatus, OrderStatus, ServiceMode, TipAmounts };
export const OrderContext = React.createContext();
export const useOrderContext = () => {
  const ctx = useContext(OrderContext);

  if (!ctx) {
    throw new Error('useOrderContext must be used within a OrderProvider');
  }

  return ctx;
};

const preloadedOrder = () => {
  const order = LocalStorage.getItem(StorageKeys.ORDER);
  if (order) {
    const { curbsidePickupOrderTimePlaced } = order;
    // We time out saved curbside order after 1 hour
    // if user has not submitted the order to prevent
    // outdated order
    if (
      !curbsidePickupOrderTimePlaced ||
      differenceInMinutes(new Date(), new Date(curbsidePickupOrderTimePlaced)) > 60
    ) {
      return {
        ...order,
        curbsidePickupOrderTimePlaced: '',
        curbsidePickupOrderId: '',
      };
    }
    return order;
  }
  return {};
};

export function OrderProvider(props) {
  const apolloClient = useApolloClient();
  const preloaded = useMemo(() => preloadedOrder(), []);

  // LD Flags
  const isAmountOfferDiscountValueAsCents = useFlag(
    LaunchDarklyFlag.ENABLE_AMOUNT_OFFER_DISCOUNT_VALUE_AS_CENTS
  );
  const upsellModalVariation = useFlag(LaunchDarklyFlag.UPSELL_MODAL);
  const premiumComboSlotPricingMethod = useFlag(LaunchDarklyFlag.ENABLE_PREMIUM_COMBO_SLOTS);
  const deliveryBannerPolling = useFlag(LaunchDarklyFlag.DELIVERY_BANNER_POLLING);
  const tipPercentThresholdCents = useFlag(LaunchDarklyFlag.TIP_PERCENT_THRESHOLD_CENTS) || 0;
  const enableFlavorFlow = useFlag(LaunchDarklyFlag.ENABLE_FLAVOR_FLOW);
  const enableLoyaltyOfferPromoCodeAtCheckout = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_OFFER_PROMO_CODE_AT_CHECKOUT
  );
  let CART_VERSION = useFlag(LaunchDarklyFlag.ORDER_CART_VERSION) || preloaded.cartVersion;
  const { formatMessage } = useIntl();
  const { formatCurrencyForLocale } = useUIContext();
  const auth = useAuthContext();
  const { onCommitOrderSuccess: onCommitGuestOrderSuccess, isAuthenticated: isGuestAuthenticated } =
    useAuthGuestContext();
  const billingCountry = auth?.user?.details?.isoCountryCode || ISOs.USA;
  const { currencyCode } = getCountryAndCurrencyCodes(billingCountry);
  const { locale: customerLocale, region } = useLocale();
  const { decorateLogger, logger } = useLoggerContext();
  const cdp = useCdpContext();
  const {
    pricingFunction,
    priceForItemOptionModifier,
    priceForItemInComboSlotSelection,
    isOffer: isOfferInCart,
  } = useMenuContext();
  const { getPaymentMethods } = usePaymentContext();
  const {
    tipAmount,
    setTipAmount,
    tipSelection,
    setTipSelection,
    updateTipAmount,
    shouldShowTipPercentage,
  } = useCartTip();

  const {
    prices,
    resetStore,
    selectStore: selectNewStore,
    store,
    resetLastTimeStoreUpdated,
    isStoreOpenAndAvailable,
    updateUserStoreWithCallback,
  } = useStoreContext();
  const { vendor } = usePosVendor();
  const { storeMenuLoading } = useMainMenuContext();
  const { serviceMode, setServiceMode } = useServiceModeContext();
  const { location, navigate } = useLocationContext();
  const { setCurrentOrderId } = useErrorContext();
  const loyaltyEnabled = useIsLoyaltyEnabled();
  const { loyaltyUser } = useLoyaltyUser();
  const { getAvailableRewardFromCartEntry, refetchLoyaltyUser, rewardLimitePerOrder } =
    useLoyaltyContext();
  const { sanityRewardsMap } = useLoyaltyRewardsList();

  const { changeImageByChannel } = useImagesByChannels();
  const { isEdit } = getOptionsFromParams(location.search);
  const appliedLoyaltyRewards = useAppSelector(selectors.loyalty.selectAppliedLoyaltyRewards);
  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const loyaltyCmsOffers = useAppSelector(selectors.loyalty.selectCmsOffers);
  const incentivesIds = useAppSelector(selectors.loyalty.selectIncentivesIds);
  const personalizedOffers = useAppSelector(selectors.loyalty.selectPersonalizedOffers);

  const discountAppliedCmsOffers = useAppSelector(selectors.loyalty.selectDiscountAppliedCmsOffer);
  const dispatch = useAppDispatch();

  const loyaltyUserId = loyaltyUser?.id;

  const { refetch: refetchLoyaltyUserTransaction } = useLoyaltyUserTransactionsQuery({
    skip: !loyaltyUserId,
    variables: { loyaltyId: loyaltyUserId || '' },
  });
  const [refetchGetUserOrders] = useGetUserOrdersLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      limit: 1,
      orderStatuses: [RbiOrderStatus.INSERT_SUCCESSFUL, RbiOrderStatus.UPDATE_SUCCESSFUL],
    },
  });

  const [executeUpdateOrderMutation] = useUpdateOrderMutation();
  const [executeCancelOrderMutation] = useCancelOrderMutation();
  const [executePriceOrderMutation] = usePriceOrderMutation();
  const [executePutFlavorFlowEventsMutation] = usePutFlavorFlowEventsMutation();

  const [pendingReorder, setPendingReorder] = useState(null);
  const [reordering, setReordering] = useState(false);
  const [reorderedOrderId, setReorderedOrderId] = useState(null);

  const [cartIdEditing, setCartIdEditing] = useState(preloaded.cartIdEditing || '');
  const [cartEntries, setCartEntries] = useState(preloaded.cartEntries || []);

  const [curbsidePickupOrderId, setCurbsidePickupOrderId] = useState(
    preloaded.curbsidePickupOrderId || ''
  );
  const [curbsidePickupOrderTimePlaced, setCurbsidePickupOrderTimePlaced] = useState(
    preloaded.curbsidePickupOrderTimePlaced || ''
  );
  const [serverOrder, setServerOrder] = useState({});
  const [quoteId, setQuoteId] = useState(preloaded.quoteId || '');
  const [cateringPickupDateTime, setOrderCateringPickupDateTime] = useState(
    preloaded.cateringPickupDateTime || ''
  );
  const [deliveryAddress, setDeliveryAddress] = useState(preloaded.deliveryAddress || {});
  const [deliveryInstructions, setDeliveryInstructions] = useState(
    preloaded.deliveryInstructions || ''
  );
  const [selectedPreOrderTimeSlot, setSelectedPreOrderTimeSlot] = useState(
    preloaded.selectedPreOrderTimeSlot || null
  );
  const [preselectedPreOrderTimeSlot, setPreselectedPreOrderTimeSlot] = useState(
    preloaded.preselectedPreOrderTimeSlot || null
  );
  const { refetch: getPosData } = usePosDataQuery({
    lazy: true,
    restaurantPosDataId: '',
    storeNumber: '',
  });
  const [fetchingPosData, setFetchingPosData] = useState(false);

  const [orderPhoneNumber, setOrderPhoneNumber] = useState(() => preloaded.orderPhoneNumber || '');
  const [orderPhoneNumberError, setOrderPhoneNumberError] = useState('');
  const [additionalDetailsInfo, setAdditionalDetailsInfo] = useState([]);
  React.useEffect(() => {
    if (orderPhoneNumber && orderPhoneNumber !== PHONE_NUMBER_DUMMY) {
      return;
    }
    if (auth?.user?.details?.phoneNumber) {
      /*For delivery, we only allow market-specific phone numbers. Therefore,
      we parse the phone number and country code. The userDetail contains the
      country code and phone number, such as +193434544.
      */
      let { country } = parsePhoneNumberAndCountryCode(auth.user.details.phoneNumber);
      if (region === country) {
        setOrderPhoneNumber(auth.user.details.phoneNumber);
      } else {
        setOrderPhoneNumber('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const [showUpsellModal, setShowUpsellModal] = useState(false);

  const [isUpdatingOrder, setIsUpdatingOrder] = useState(false);
  const [isPricingOrder, setIsPricingOrder] = useState(false);
  const [fireOrderIn, setFireOrderIn] = useState(0);
  const [cartPriceLimitExceeded, setCartPriceLimitExceeded] = useState(false);
  const [isCartPriceAboveMinimumSpend, setIsCartPriceAboveMinimumSpend] = useState(true);
  const [minimumSpendAmount, setMinimumSpendAmount] = useState([]);
  const [cartPriceTooLow, setCartPriceTooLow] = useState(false);
  const [cartCateringPriceTooLow, setCartCateringPriceTooLow] = useState(false);

  const isDelivery = serviceMode === ServiceMode.DELIVERY;
  const refPriceOrderTimeout = useRef(null);

  const { unavailableCartEntries, setUnavailableCartEntries } = useUnavailableCartEntries({
    serverOrder,
    cartEntries,
  });
  const [pendingRecentItem, setPendingRecentItem] = useState();
  const [pendingRecentItemNeedsReprice, setPendingRecentItemNeedsReprice] = useState(false);

  //create this useState for update savedAddress in the details cart
  const [savedDeliveryAddress, setSavedDeliveryAddress] = useState();

  // th specific: reward information
  const { cartHasRewardEligibleItem } = useRewardDiscount({
    serverOrder,
    cartEntries,
  });

  const updateShouldSaveDeliveryAddress = useCallback(shouldSaveDeliveryAddress => {
    setDeliveryAddress(previousAddress => ({
      ...previousAddress,
      shouldSave: shouldSaveDeliveryAddress,
    }));
  }, []);

  const orderLimitMessage = (maxLimit, maxCateringLimit, isOrderCatering) => {
    const limit = determineLimit({ maxLimit, maxCateringLimit, isCatering: isOrderCatering });
    return formatMessage(
      { id: 'orderLimitMessage' },
      {
        maxLimit: formatCurrencyForLocale(limit),
      }
    );
  };

  const shouldOfferBeRemoved = useCallback(
    item => {
      const cartEntriesWithRemovedItem = cartEntries.filter(entry => entry._id !== item._id);

      let total = computeCartTotal(
        cartEntriesWithRemovedItem,
        {
          loyaltyEnabled,
          appliedLoyaltyRewards,
          appliedLoyaltyOfferDiscount: discountAppliedCmsOffers?.incentives?.[0],
        },
        isAmountOfferDiscountValueAsCents
      );
      total = total < 0 ? 0 : total;

      return total;
    },
    [
      cartEntries,
      loyaltyEnabled,
      appliedLoyaltyRewards,
      discountAppliedCmsOffers?.incentives,
      isAmountOfferDiscountValueAsCents,
    ]
  );

  const getOfferText = item =>
    shouldOfferBeRemoved(item) ? formatMessage({ id: 'removeItemFromCartOfferText' }) : '';

  const removeItemMessage = item => {
    return item
      ? formatMessage(
          { id: 'removeItemFromCart' },
          {
            item: item.name,
            offerText: getOfferText(item),
          }
        )
      : '';
  };

  const emptyCart = useCallback(
    (commitSuccess = false) => {
      setCartEntries([]);
      setCartIdEditing('');
      setTipAmount(0);
      setTipSelection({
        percentAmount: TipAmounts.PERCENT_DEFAULT,
        dollarAmount: TipAmounts.DOLLAR_DEFAULT,
        otherAmount: 0,
        isOtherSelected: false,
      });

      removeAppliedOffersInStorage();
      removeAppliedRewardsInStorage();

      if (enableLoyaltyOfferPromoCodeAtCheckout && commitSuccess) {
        removeSelectedOfferInStorage();
      }

      if (loyaltyEnabled) {
        dispatch(actions.loyalty.resetAppliedOffers());
        dispatch(actions.loyalty.resetLoyaltyRewardsState(loyaltyUser?.points ?? 0));
        if (enableLoyaltyOfferPromoCodeAtCheckout && commitSuccess) {
          dispatch(actions.loyalty.resetAllConfigOffers());
          appliedOffers?.forEach(offer => {
            const newOffer = { _id: offer.cmsId };
            dispatch(actions.loyalty.removeCmsOfferByLoyalty(newOffer));
          });
        }
      }
    },
    [
      setTipAmount,
      setTipSelection,
      enableLoyaltyOfferPromoCodeAtCheckout,
      loyaltyEnabled,
      dispatch,
      loyaltyUser?.points,
      appliedOffers,
    ]
  );

  useSetResetCartTimeout({
    storageKey: StorageKeys.ORDER_LAST_UPDATE,
    cart: cartEntries,
    resetCartCallback: emptyCart,
  });

  useEffectOnUpdates(() => {
    if (!auth.isAuthenticated()) {
      emptyCart();
    }
  }, [auth.user]); // eslint-disable-line react-hooks/exhaustive-deps

  const verifyCartVersion = useCallback(
    (version, { onFailure = noop, onSuccess = noop }) => {
      if (version !== CART_VERSION) {
        onFailure();
        return;
      }
      onSuccess();
    },
    [CART_VERSION]
  );

  useEffect(() => {
    const updateStoredCartVersion = () =>
      LocalStorage.setItem(StorageKeys.ORDER, {
        ...preloaded,
        cartVersion: CART_VERSION,
      });

    const cartVersionArgs = {
      message: cartEntries.length > 0 ? formatMessage({ id: 'outdatedCartVersionMessage' }) : '',
      onFailure: () => {
        if (cartEntries.length > 0) {
          emptyCart();
        }
        updateStoredCartVersion();
      },
    };

    verifyCartVersion(preloaded.cartVersion || 0, cartVersionArgs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CART_VERSION]);

  useEffect(() => {
    setCurrentOrderId(serverOrder.rbiOrderId);
  }, [serverOrder, setCurrentOrderId]);

  useEffectOnUpdates(() => {
    // Creating a map out of all valid offers in the CMS
    const cmsOffersMap = getCmsOffersMapByCmsId(loyaltyCmsOffers);

    appliedOffers.forEach(({ cartId, cmsId, type }) => {
      // If the applied offer is not in the cms offers map, should remove it
      const shouldRemoveCartEntry =
        (!cmsId || !cmsOffersMap[cmsId]) && type !== OfferType.PERSONALIZED;
      // eslint-disable-next-line no-undef
      if (shouldRemoveCartEntry) {
        // Removing offer from cart will also remove it from applied offers
        removeFromCart({ cartId });
      }
    });
  }, [loyaltyCmsOffers]);

  useEffectOnUpdates(() => {
    const personalizedOffersMap = getCmsOffersMapByCmsId(personalizedOffers);

    appliedOffers.forEach(({ cartId, cmsId, type }) => {
      // If the applied offer is not in the cms offers map, should remove it
      const shouldRemoveCartEntry =
        (!cmsId || !personalizedOffersMap[cmsId]) && type === OfferType.PERSONALIZED;
      // eslint-disable-next-line no-undef
      if (shouldRemoveCartEntry) {
        // Removing offer from cart will also remove it from applied offers
        removeFromCart({ cartId });
      }
    });
  }, [personalizedOffers]);

  const selectServiceMode = useCallback(
    newMode => {
      dispatch(actions.loyalty.setOffersLoading(true));

      cdp.selectServiceMode(newMode);
      setFireOrderIn(0);
      setTipSelection({
        percentAmount: TipAmounts.PERCENT_DEFAULT,
        dollarAmount: TipAmounts.DOLLAR_DEFAULT,
        otherAmount: 0,
        isOtherSelected: false,
      });
      setServiceMode(newMode);
      resetLastTimeStoreUpdated();
      return Promise.resolve(true);
    },
    [dispatch, cdp, setTipSelection, setServiceMode, resetLastTimeStoreUpdated]
  );

  const logCartEntryRemovedFromCart = useCallback(
    cartEntry => {
      cdp.removeFromCart(cartEntry);
      if (cartEntry.isUpsell) {
        cdp.logUpsellRemovedEvent(cartEntry);
      }
    },
    [cdp]
  );

  const removeRewardIfNeeded = useCallback(
    cartEntry => {
      const { cartId } = cartEntry;
      const cartEntryReward = getAvailableRewardFromCartEntry(cartEntry);

      const isRewardApplied = !!appliedLoyaltyRewards?.[cartId]?.timesApplied;
      if (loyaltyEnabled && isRewardApplied && cartEntryReward) {
        dispatch(
          actions.loyalty.removeAppliedReward({
            rewardBenefitId: cartEntryReward.rewardBenefitId,
            cartId,
          })
        );
      }
    },
    [appliedLoyaltyRewards, getAvailableRewardFromCartEntry, loyaltyEnabled, dispatch]
  );

  // Updates the cartIdEditing to the new entry only if it exists
  const editCart = useCallback(
    cartId => {
      if (cartEntries.find(({ cartId: entryCartId }) => entryCartId === cartId)) {
        setCartIdEditing(cartId);
      }
    },
    [cartEntries]
  );

  // Returns back the currently editing cartEntry or undefined if the entry no longer exists
  const getCurrentCartEntry = useCallback(() => {
    return cartEntries.find(entry => entry.cartId === cartIdEditing);
  }, [cartEntries, cartIdEditing]);

  // Removes all provided cartEntries from the cart using their cartIds
  const removeAllFromCart = useCallback(
    (cartEntriesToRemove = []) => {
      const cartEntryIdsToRemove = new Set(cartEntriesToRemove.map(entry => entry.cartId));
      setCartEntries(prevCartEntries =>
        prevCartEntries.filter(entry => !cartEntryIdsToRemove.has(entry.cartId))
      );
      cartEntriesToRemove.forEach(cartEntry => {
        removeRewardIfNeeded(cartEntry);
        logCartEntryRemovedFromCart(cartEntry);
        dispatch(actions.loyalty.removeAppliedOfferByCartEntry(cartEntry));
      });
    },
    [dispatch, logCartEntryRemovedFromCart, removeRewardIfNeeded]
  );

  // Removes a single cartEntry using its cartId
  const removeFromCart = useCallback(
    ({ cartId }) => {
      const cartEntryToRemove = cartEntries.find(entry => entry.cartId === cartId);

      if (cartId === 'discount-offer') {
        dispatch(actions.loyalty.removeAppliedDiscountOffer());
        return;
      }

      if (!cartEntryToRemove) {
        return;
      }

      const isOfferEntryAddedAtCheckout = appliedOffers.some(
        appliedOffer =>
          appliedOffer.cartId === cartEntryToRemove?.cartId && appliedOffer.isAppliedAtCheckout
      );

      if (!isOfferEntryAddedAtCheckout || (isOfferEntryAddedAtCheckout && !isEdit)) {
        dispatch(actions.loyalty.removeAppliedOfferByCartEntry(cartEntryToRemove));
      }

      setCartEntries(prevCartEntries =>
        prevCartEntries.filter(entry => cartEntryToRemove.cartId !== entry.cartId)
      );
      logCartEntryRemovedFromCart(cartEntryToRemove);

      // removes applied rewards associated to cart entry on removal
      removeRewardIfNeeded(cartEntryToRemove);

      // Resets the availability of Surprise so it wont show in cart page
      dispatch(actions.loyalty.resetSurpriseAvailability());
    },
    [
      cartEntries,
      appliedOffers,
      isEdit,
      logCartEntryRemovedFromCart,
      shouldOfferBeRemoved,
      removeRewardIfNeeded,
      dispatch,
    ]
  );

  const removeRewardsFromCart = useCallback(
    rewardIds => {
      dispatch(actions.loyalty.removeAppliedRewards({ cartIds: rewardIds }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (incentivesIds.size > 0) {
      validateCartEntries(cartEntries, appliedOffers, incentivesIds, isOfferInCart, removeFromCart);
    }
  }, [appliedOffers, cartEntries, incentivesIds, isOfferInCart, removeFromCart]);

  useEffect(() => {
    if (!isEmpty(appliedLoyaltyRewards) && rewardLimitePerOrder) {
      validateCartRewards({
        appliedRewards: appliedLoyaltyRewards,
        rewardLimitePerOrder,
        validateCallback: removeRewardsFromCart,
      });
    }
  }, [appliedLoyaltyRewards, rewardLimitePerOrder, removeRewardsFromCart]);

  const shouldEmptyCart = ({ cartId }) => {
    const appliedOffersMap = appliedOffers.reduce((acc, appliedOffer) => {
      if (appliedOffer.cartId) {
        acc[appliedOffer.cartId] = appliedOffer;
      }
      return acc;
    }, {});

    const entriesNotRemoved = cartEntries.filter(entry => entry.cartId !== cartId);
    const entryIsDonationOrSurprise = entry =>
      entry.isDonation || appliedOffersMap[entry?.cartId]?.isSurprise;

    return entriesNotRemoved.length && entriesNotRemoved.every(entryIsDonationOrSurprise);
  };

  const [RemoveItemDialog, openRemoveItemDialog, itemToRm] = useDialogModal({
    onConfirm: entry => {
      return shouldEmptyCart(entry) ? emptyCart() : removeFromCart(entry);
    },
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Remove item from cart',
  });

  // for confirming item removal
  const confirmRemoveFromCart = useCallback(
    cartId => {
      const entryToRemove = cartEntries.find(item => cartId === item.cartId);
      openRemoveItemDialog(entryToRemove);
    },
    [cartEntries, openRemoveItemDialog]
  );

  const calculateCartTotalWithoutOffers = useCallback(() => {
    return computeTotalWithoutOffers(
      cartEntries,
      { loyaltyEnabled, appliedLoyaltyRewards },
      isAmountOfferDiscountValueAsCents
    );
  }, [appliedLoyaltyRewards, cartEntries, isAmountOfferDiscountValueAsCents, loyaltyEnabled]);

  const calculateCartTotalWithDiscount = useCallback(() => {
    const total = computeCartTotal(
      cartEntries,
      {
        loyaltyEnabled,
        appliedLoyaltyRewards,
        appliedLoyaltyOfferDiscount: discountAppliedCmsOffers?.incentives?.[0],
        appliedOffers,
        loyaltyCmsOffers,
        sanityRewardsMap,
        prices,
        vendor,
      },
      isAmountOfferDiscountValueAsCents
    );
    return {
      cartTotal: total,
      isCartTotalNegative: total < 0,
    };
  }, [
    cartEntries,
    loyaltyEnabled,
    appliedLoyaltyRewards,
    discountAppliedCmsOffers?.incentives,
    appliedOffers,
    sanityRewardsMap,
    prices,
    vendor,
    isAmountOfferDiscountValueAsCents,
  ]);

  const calculateCartTotal = useCallback(() => {
    const { cartTotal, isCartTotalNegative } = calculateCartTotalWithDiscount();
    return isCartTotalNegative ? 0 : cartTotal;
  }, [calculateCartTotalWithDiscount]);

  const logCartStoreAndTimeout = useCallback(
    (resetStoreTimeout, timeSinceLastVisit) => {
      const storeDetails = {
        cartEntriesTotal: cartEntries.length,
        storeId: store._id,
        itemNames: cartEntries.map(entry => entry.name),
      };

      const { cartEntriesTotal, storeId, itemNames } = storeDetails;

      cdp.trackEvent({
        name: CustomEventNames.SESSION_RESET_FROM_INACTIVITY,
        type: EventTypes.Other,
        attributes: {
          storeDetails: `cartEntriesTotal: ${cartEntriesTotal}, storeId: ${storeId}, itemNames: ${JSON.stringify(
            itemNames
          )} `,
          resetStoreTimeoutSeconds: resetStoreTimeout,
          hoursSinceLastVisit: parseInt((timeSinceLastVisit / (1000 * 60 * 60)).toFixed(1)),
        },
      });
    },
    [cartEntries, cdp, store._id]
  );

  const clearCartStoreServiceModeTimeout = useCallback(() => {
    selectServiceMode(null);
    setUnavailableCartEntries([]);
    emptyCart();
    resetStore();
    LocalStorage.removeItem(StorageKeys.LAST_TIME_STORE_UPDATED);
  }, [emptyCart, resetStore, selectServiceMode, setUnavailableCartEntries]);

  const checkoutPriceLimit = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_LIMIT);
  const checkoutDeliveryPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_DELIVERY_MINIMUM);
  const checkoutCateringPriceLimit = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_LIMIT);
  const checkoutCateringPriceMinimum = useFlag(LaunchDarklyFlag.OVERRIDE_CHECKOUT_CATERING_MINIMUM);

  const [OrderLimitDialog, openOrderLimitDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Order limit reached',
  });

  const alertOrderLimit = useCallback(() => {
    checkLimitReachedEvent(calculateCartTotal(), cdp);
    if (location.pathname.startsWith(routes.cart)) {
      openOrderLimitDialog();
    }
  }, [calculateCartTotal, cdp, location.pathname, openOrderLimitDialog]);

  const alertOrderDeliveryMinimum = useCallback(() => {
    if (
      cartEntries.length > 0 &&
      isDelivery &&
      calculateCartTotal() < checkoutDeliveryPriceMinimum
    ) {
      checkMinimumNotReachedEvent(calculateCartTotal(), cdp);
    }
  }, [cdp, calculateCartTotal, cartEntries.length, checkoutDeliveryPriceMinimum, isDelivery]);

  const alertOrderCateringMinimum = useCallback(() => {
    if (
      cartEntries.length > 0 &&
      isCateringOrder(serviceMode) &&
      calculateCartTotal() < checkoutCateringPriceMinimum
    ) {
      checkMinimumNotReachedEvent(calculateCartTotal(), cdp);
    }
  }, [cdp, calculateCartTotal, cartEntries.length, checkoutCateringPriceMinimum, serviceMode]);
  const updateQuantity = useCallback(
    (cartId, quantity) => {
      if (quantity < 1) {
        return removeFromCart({ cartId });
      }

      setCartEntries(prevCartEntries =>
        prevCartEntries.map(entry => {
          if (entry.cartId === cartId) {
            entry.quantity = quantity;
            entry.price = pricingFunction(entry, entry.quantity);
          }

          return entry;
        })
      );
    },
    [pricingFunction, removeFromCart]
  );

  // Updates a single CartEntry
  const updateCartEntry = useCallback(
    (newCartEntry, originalEntry) => {
      // update item in cdp cart
      cdp.updateItemInCart(newCartEntry, originalEntry, serviceMode);

      // If the original entry has a reward applied but the new entry is a different item,
      // then remove the reward.
      const appliedReward = appliedLoyaltyRewards[originalEntry?.cartId];
      if (appliedReward && appliedReward.rewardBenefitId !== newCartEntry._id) {
        dispatch(
          actions.loyalty.removeAppliedReward({
            rewardBenefitId: appliedReward.rewardBenefitId,
            cartId: originalEntry.cartId,
          })
        );
      }

      setCartEntries(prevCartEntries => replaceEntryArrayItem(prevCartEntries, newCartEntry));
      toast.success(formatMessage({ id: 'updateCartSuccess' }, { itemName: newCartEntry.name }));
    },
    [appliedLoyaltyRewards, dispatch, formatMessage, cdp, serviceMode]
  );

  // Updates multiple CartEntries at once
  const updateMultipleCartEntries = useCallback(
    (entriesToUpdate, allOriginalEntries) => {
      entriesToUpdate.forEach(newEntry =>
        updateCartEntry(
          newEntry,
          allOriginalEntries.find(originalEntry => originalEntry.cartId === newEntry.cartId)
        )
      );
    },
    [updateCartEntry]
  );

  const openUpsellModal = () => {
    const flagEnabled =
      upsellModalVariation === UpsellModalVariations.MENU ||
      upsellModalVariation === UpsellModalVariations.MENU_AND_CHECKOUT;

    if (flagEnabled && cartEntries.length === 0) {
      setShowUpsellModal(true);
    }
  };

  // Adds a single cartEntry to the cart
  const addToCart = useCallback(
    (cartEntry, eventAttrs) => {
      setCartEntries(prevCartEntries => {
        if (enableFlavorFlow) {
          executePutFlavorFlowEventsMutation({
            variables: {
              productDetails: [
                {
                  id: cartEntry._id,
                  quantity: cartEntry.quantity,
                  type: cartEntry.type,
                },
              ],
              serviceMode,
              storeId: store.number,
              eventType: FlavorFlowType.ADD_TO_CART,
            },
          });
        }
        cdp.addToCart(cartEntry, serviceMode, prevCartEntries, eventAttrs);
        return prevCartEntries.concat([cartEntry]);
      });

      if (cartEntry.isDonation) {
        return;
      }
      toast.success(formatMessage({ id: 'addToCartSuccess' }, { itemName: cartEntry.name }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setCartEntries, cdp, serviceMode, formatMessage]
  );

  // Adds multiple cart entries to the cart
  const addMultipleToCart = useCallback(
    (newCartEntries, eventAttrs) => {
      newCartEntries.forEach(entry => {
        addToCart(entry, eventAttrs);
      });
      openUpsellModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToCart, setShowUpsellModal, openUpsellModal, upsellModalVariation]
  );

  // Adds or Updates CartEntries
  // Accepts CartEntries and will determain which ones are being updated and which ones are new using their cartIds
  const upsertCart = useCallback(
    (newCartEntries, eventAttrs) => {
      // If there are no items in the cart already just add all new entries to the cart
      if (!cartEntries.length) {
        addMultipleToCart(newCartEntries, eventAttrs);
        return;
      }

      const existingEntries = intersectionBy(newCartEntries, cartEntries, 'cartId');
      const newEntries = differenceBy(newCartEntries, cartEntries, 'cartId');

      addMultipleToCart(newEntries, eventAttrs);
      updateMultipleCartEntries(existingEntries, cartEntries);
    },
    [addMultipleToCart, cartEntries, updateMultipleCartEntries]
  );

  const logOrderLatencyDuration = useCallback(
    (actionType, remoteOrder) => {
      if (!['commit', 'price'].includes(actionType)) {
        return;
      }

      const { endMark, startMark } =
        actionType === 'price'
          ? { endMark: PerformanceMarks.PRICE_END, startMark: PerformanceMarks.PRICE_START }
          : { endMark: PerformanceMarks.COMMIT_END, startMark: PerformanceMarks.COMMIT_START };

      const measure = actionType === 'price' ? Measures.PRICE : Measures.COMMIT;

      const duration = getMeasureAndClearMarks(measure, startMark, endMark);

      if (duration !== undefined) {
        cdp.logOrderLatencyEvent(remoteOrder, actionType, duration);
      }
    },
    [cdp]
  );

  const swapItems = useCallback(
    (from, to, offer) => {
      setCartEntries(prevEntries => {
        // Root cart entry is needed for appliedOffers
        // Child cart entry is needed for swaps objects
        const { rootEntry, childEntry } = findEntriesByCmsId(prevEntries, from);
        let entriesToReturn = prevEntries;

        if (rootEntry) {
          const oldEntry = rootEntry;
          // Define price for item
          const oldEntryUnitaryPrice = oldEntry.price / oldEntry.quantity;
          const { type: swapType, offerId, offerType, cmsId } = offer;

          const entryToSwap = {
            ...createCartEntry({
              item: to,
              price: oldEntry.children.length ? 0 : oldEntryUnitaryPrice,
              quantity: 1,
            }),
            sanityId: to._id,
          };

          let offerToApply = {
            id: offerId,
            cartId: oldEntry.children.length ? oldEntry.cartId : entryToSwap.cartId,
            type: offerType,
            swap: {
              cartId: entryToSwap.cartId,
              from,
              to: to._id,
              swapType,
            },
            cmsId,
          };

          // This covers the case when a cartEntry has more than one item and swap is selected
          // updating price and quantity to the cartEntry that was swapped
          if (oldEntry.quantity > 1) {
            oldEntry.quantity--;
            oldEntry.price = oldEntryUnitaryPrice * oldEntry.quantity;

            // If cartEntry has children, we keep a copy of the entry without
            // the swap applied to concat to the entries array after applying the swap
            if (oldEntry.children.length) {
              const splittedItem = cloneDeep(oldEntry);
              splittedItem.cartId = uuidv4();
              offerToApply.cartId = oldEntry.cartId;
              // Creating new cart entry with swap applied to replace at root level
              // having quantity and price correct for a swapped item
              const newRoot = {
                ...oldEntry,
                children: replaceEntry(oldEntry.children, from, { ...entryToSwap, price: 0 }),
                price: oldEntryUnitaryPrice,
                quantity: 1,
              };

              entriesToReturn = replaceEntry(prevEntries, oldEntry._id, newRoot).concat(
                splittedItem
              );
            } else {
              entriesToReturn = prevEntries.concat(entryToSwap);
            }
          } else {
            entriesToReturn = replaceEntry(prevEntries, from, entryToSwap);
          }

          dispatch(actions.loyalty.applyOffer(offerToApply));

          cdp.trackEvent({
            name: CustomEventNames.SWAP_OFFER_USED,
            type: EventTypes.Other,
            attributes: {
              'Original Item Name': childEntry.name,
              'Original Item Sanity ID': from,
              'Swapped Item Name': to.name?.locale,
              'Swapped Item Sanity ID': to._id,
              'Systemwide Offer ID': offer.offerId,
            },
          });
        }

        return entriesToReturn;
      });
    },
    [setCartEntries, dispatch, cdp]
  );

  const queryOrder = useCallback(
    async rbiOrderId => {
      try {
        const { data, errors } = await apolloClient.query({
          fetchPolicy: 'network-only',
          query: GetOrderDocument,
          variables: {
            rbiOrderId,
          },
        });

        if (errors) {
          throw errors;
        }

        return data?.order;
      } catch (error) {
        logger.error({ error, message: 'Error querying order' });
        throw error;
      }
    },
    [apolloClient, logger]
  );

  // commit was being fired twice, which was firing onCommitSuccess twice
  // the lastPurchaseOrderRId checks if the same order is being committed twice
  // where we set .current = rbiOrderID is on the first run, so the second run will return early
  const lastPurchaseOrderId = useRef();
  const onCommitSuccess = useCallback(
    async remoteOrder => {
      if (lastPurchaseOrderId.current === remoteOrder.rbiOrderId) {
        return;
      }
      if (remoteOrder?.cart?.serviceMode === ServiceMode.CURBSIDE) {
        setCurbsidePickupOrderId('');
        setCurbsidePickupOrderTimePlaced('');
      }
      lastPurchaseOrderId.current = remoteOrder.rbiOrderId;
      emptyCart();
      const cartEntriesData = cartEntries;
      const quotedFeeCents = remoteOrder.delivery?.quotedFeeCents || 0;
      const deliveryFeeCents = remoteOrder.delivery?.feeCents || 0;
      const deliveryFeeDiscountCents = remoteOrder.delivery?.feeDiscountCents || 0;
      const deliveryGeographicalFeeCents = remoteOrder.delivery?.geographicalFeeCents || 0;
      const deliveryServiceFeeCents = remoteOrder.delivery?.serviceFeeCents || 0;
      const deliverySmallCartFeeCents = remoteOrder.delivery?.smallCartFeeCents || 0;
      const baseDeliveryFeeCents = remoteOrder.delivery?.baseDeliveryFeeCents || 0;
      const deliverySurchargeFeeCents = remoteOrder.delivery?.deliverySurchargeFeeCents || 0;

      let deliveryAddressDetails;
      try {
        deliveryAddressDetails = JSON.parse(LocalStorage.getItem(StorageKeys.DELIVERY_ADDRESS));
      } catch (e) {
        // Do nothing if it doesn't exist
      }
      if (enableFlavorFlow) {
        executePutFlavorFlowEventsMutation({
          variables: {
            productDetails: cartEntriesData.map(item => ({
              id: item._id,
              quantity: item.quantity,
              type: item.type,
            })),
            serviceMode,
            storeId: store.number,
            eventType: FlavorFlowType.CHECKOUT,
          },
        });
      }

      let customerCurrencyCode = currencyCode;

      const isGuestOrder = !!remoteOrder.cart.guestId;
      if (isGuestOrder) {
        customerCurrencyCode = getCountryAndCurrencyCodes(
          remoteOrder.cart.guestDetails.isoCountryCode
        ).currencyCode;
      }

      cdp.logPurchase(cartEntriesData, store, serviceMode, remoteOrder, {
        currencyCode: customerCurrencyCode,
        quotedFeeCents,
        baseDeliveryFeeCents,
        totalDeliveryOrderFeesCents:
          baseDeliveryFeeCents +
          deliverySurchargeFeeCents +
          deliveryServiceFeeCents +
          deliverySmallCartFeeCents +
          deliveryGeographicalFeeCents -
          deliveryFeeDiscountCents,
        deliveryFeeCents:
          deliveryFeeCents -
          deliveryFeeDiscountCents -
          deliveryGeographicalFeeCents -
          deliveryServiceFeeCents -
          deliverySmallCartFeeCents,
        deliverySurchargeFeeCents,
        deliveryFeeDiscountCents,
        deliveryGeographicalFeeCents,
        deliveryServiceFeeCents,
        deliverySmallCartFeeCents,
        fireOrderInMinutes: Math.round(fireOrderIn / 60),
        addressType: deliveryAddressDetails?.location, // recent addresses will be passed as location as well
        hasSelectedRecentAddress:
          !!deliveryAddressDetails?.location && deliveryAddressDetails.location === 'recent',
        hasSavedDeliveryAddress:
          !!deliveryAddressDetails?.location && deliveryAddressDetails.location !== 'recent', // If address has a name it means it was saved now or previously. If the name is recent, it comes from recent address
      });
      setServerOrder(remoteOrder);

      try {
        await Promise.all([
          // refresh the user's payment methods stored
          // in state, including gift cards
          getPaymentMethods(),
          // refresh last order for delivery banner
          deliveryBannerPolling ? refetchGetUserOrders() : Promise.resolve(),
          // refresh loyalty user points and recent transactions
          loyaltyUserId ? refetchLoyaltyUser() : Promise.resolve(),
          loyaltyUserId ? refetchLoyaltyUserTransaction() : Promise.resolve(),
          // refetch loyalty rewards
          dispatch(actions.loyalty.setShouldRefetchRewards(true)),
        ]);
        if (isGuestAuthenticated()) {
          onCommitGuestOrderSuccess(remoteOrder);
        }
      } catch (error) {
        logger.error({ error, message: 'Error after commit success' });
      }
    },
    [
      emptyCart,
      cartEntries,
      cdp,
      store,
      serviceMode,
      currencyCode,
      fireOrderIn,
      getPaymentMethods,
      deliveryBannerPolling,
      refetchGetUserOrders,
      loyaltyUserId,
      refetchLoyaltyUser,
      refetchLoyaltyUserTransaction,
      dispatch,
      logger,
      onCommitGuestOrderSuccess,
      isGuestAuthenticated,
    ]
  );

  const price = useCallback(
    async paymentMethod => {
      setMark(PerformanceMarks.PRICE_START);

      const pollForPrice = async orderId => {
        if (!location.pathname.startsWith(routes.cart)) {
          clearTimeout(refPriceOrderTimeout.current);

          return Promise.resolve(null);
        }

        const remoteOrder = await queryOrder(orderId);

        const success = orderPollSuccessful({
          deliverySuccessStatus: DeliveryStatus.QUOTE_SUCCESSFUL,
          isDelivery,
          order: remoteOrder,
          orderSuccessStatus: OrderStatus.PRICE_SUCCESSFUL,
        });
        const failure = orderPollFailure({
          deliveryFailureStatus: [DeliveryStatus.QUOTE_ERROR, DeliveryStatus.QUOTE_UNAVAILABLE],
          isDelivery,
          order: remoteOrder,
          orderFailureStatus: OrderStatus.PRICE_ERROR,
        });

        if (success || failure) {
          setMark(PerformanceMarks.PRICE_END);

          logOrderLatencyDuration('price', remoteOrder);
        }

        if (success) {
          setServerOrder(remoteOrder);

          if (isDelivery) {
            const otherDiscountAmount = computeOtherDiscountAmount(remoteOrder.cart.discounts);
            updateTipAmount({ subTotalCents: remoteOrder.cart.subTotalCents, otherDiscountAmount });
          }

          return remoteOrder.status;
        } else if (failure) {
          setServerOrder(remoteOrder || {});

          throw new Error(OrderStatus.PRICE_ERROR);
        } else {
          return new Promise(resolve => {
            refPriceOrderTimeout.current = setTimeout(
              () => resolve(pollForPrice(remoteOrder.rbiOrderId)),
              1000
            );
          });
        }
      };

      const priceInCents = calculateCartTotal(cartEntries);
      const storeAddress = buildStoreAddress(store);
      const remappedCartEntries = remappedCartForBackEnd(cartEntries);

      const orderInput = {
        calculateCartTotal,
        cartEntries,
        cartVersion: CART_VERSION,
        customerLocale,
        customerName: null,
        deliveryAddress,
        orderPhoneNumber,
        paymentMethod,
        serviceMode,
        store,
      };

      const deliveryInput = buildPriceDeliveryInput(orderInput, auth.user, quoteId);

      try {
        const { data, errors } = await executePriceOrderMutation({
          variables: {
            delivery: deliveryInput,
            input: {
              ...orderInput,
              brand: brand().toUpperCase(),
              storeAddress,
              cartEntries: remappedCartEntries,
              platform: platform(),
              posVendor: store.pos.vendor,
              requestedAmountCents: Math.round(priceInCents),
              storeId: store.number,
              storePosId: store.posRestaurantId,
              appliedOffers,
              vatNumber: store.vatNumber,
            },
          },
        });

        if (errors) {
          throw errors;
        }

        const remoteOrder = data.priceOrder;

        if (!remoteOrder) {
          throw new Error(OrderStatus.PRICE_ERROR);
        }
        return pollForPrice(remoteOrder.rbiOrderId);
      } catch (error) {
        logger.error({ error, message: 'Error pricing order' });
        throw error;
      }
    },
    [
      cartEntries,
      calculateCartTotal,
      store,
      CART_VERSION,
      customerLocale,
      deliveryAddress,
      orderPhoneNumber,
      serviceMode,
      auth.user,
      quoteId,
      location.pathname,
      queryOrder,
      isDelivery,
      logOrderLatencyDuration,
      updateTipAmount,
      executePriceOrderMutation,
      appliedOffers,
      logger,
    ]
  );

  const getAndRefreshServerOrder = useCallback(
    async id => {
      try {
        const order = await queryOrder(id);
        setServerOrder(order || {});
        return order;
      } catch (error) {
        logger.error({ error, message: 'Error refreshing order' });
      }
    },
    [queryOrder, logger]
  );

  const fireOrderInXSeconds = useCallback(
    async ({ rbiOrderId, timeInSeconds }) => {
      try {
        setIsUpdatingOrder(true);
        const { data, errors } = await executeUpdateOrderMutation({
          variables: {
            input: {
              fireOrderIn: timeInSeconds,
              rbiOrderId,
            },
          },
        });

        setIsUpdatingOrder(false);

        if (errors) {
          throw errors;
        }

        return getAndRefreshServerOrder(data?.updateOrder?.rbiOrderId);
      } catch (error) {
        logger.error({ error, message: 'Error updating order.' });
        throw error;
      }
    },
    [executeUpdateOrderMutation, getAndRefreshServerOrder, logger, setIsUpdatingOrder]
  );

  const cancelOrder = useCallback(
    async ({ rbiOrderId, onCompleted }) => {
      try {
        return await executeCancelOrderMutation({
          variables: {
            input: {
              rbiOrderId,
            },
          },
          onCompleted: onCompleted(),
          onError: error => {
            throw error;
          },
        });
      } catch (error) {
        logger.error({ error, message: 'Error updating order.' });
        throw error;
      }
    },
    [executeCancelOrderMutation, logger]
  );

  const clearServerOrder = () => {
    setServerOrder({});
  };

  // make sure we persist everything!
  useEffect(() => {
    LocalStorage.setItem(StorageKeys.ORDER, {
      cartEntries,
      cartIdEditing,
      cartVersion: CART_VERSION,
      cateringPickupDateTime,
      deliveryAddress,
      deliveryInstructions,
      selectedPreOrderTimeSlot,
      preselectedPreOrderTimeSlot,
      quoteId,
      orderPhoneNumber,
      curbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
    });
  }, [
    cartEntries,
    cateringPickupDateTime,
    serviceMode,
    deliveryInstructions,
    selectedPreOrderTimeSlot,
    preselectedPreOrderTimeSlot,
    orderPhoneNumber,
    deliveryAddress,
    curbsidePickupOrderId,
    curbsidePickupOrderTimePlaced,
    cartIdEditing,
    CART_VERSION,
    quoteId,
  ]);

  // Configure the logger to hold some information
  useEffect(() => {
    const extras = {
      cartEntries: JSON.stringify(cartEntries, null, 4),
      serviceMode,
    };

    if (serverOrder.rbiOrderId) {
      DatadogLogger.addContext('transaction_id', serverOrder.rbiOrderId);
    }

    decorateLogger({ ...extras, transactionId: serverOrder.rbiOrderId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartEntries, serverOrder.rbiOrderId, serviceMode, store]);

  const isCartEntryInSwaps = useCallback(
    cartEntry =>
      appliedOffers.some(offer =>
        cartEntry?.children?.length
          ? offer.cartId === cartEntry.cartId
          : offer?.swap?.cartId === cartEntry.cartId
      ),
    [appliedOffers]
  );

  const getItemPriceForRepricing = useCallback(
    (entry, parentEntry, mainCombo) => {
      const isComboSlot = parentEntry?.type === CartEntryType.comboSlot;
      const isDelta = premiumComboSlotPricingMethod === EnablePremiumComboSlotsVariations.DELTA;

      if (!isComboSlot) {
        return pricingFunction(entry, entry.quantity);
      }

      // If pricing method is delta, price should not be calculated
      return isDelta
        ? entry.price
        : priceForItemInComboSlotSelection({
            combo: mainCombo,
            comboSlot: parentEntry,
            selectedItem: entry,
          });
    },
    [premiumComboSlotPricingMethod, priceForItemInComboSlotSelection, pricingFunction]
  );

  // When repricing cart entry, we need a record of parent and main combo in order to price combo item
  const repriceCartEntriesHelper = useCallback(
    (entries, parentEntry, mainCombo) =>
      entries.map(entry => {
        const hasPrice = entry.price !== undefined;
        const isItem = entry.type === CartEntryType.item;
        const isCombo = entry.type === CartEntryType.combo;
        const isSwap = isCartEntryInSwaps(entry);

        if (isItem) {
          return {
            ...entry,
            // prices are not defined on main items in combos, we need to preserve this
            ...(!isSwap &&
              hasPrice && { price: getItemPriceForRepricing(entry, parentEntry, mainCombo) }),
            children: (entry.children || []).map(itemOption => ({
              ...itemOption,
              children: (itemOption.children || []).map(modifier => ({
                ...modifier,
                price: priceForItemOptionModifier({ item: entry, itemOption, modifier }),
              })),
            })),
          };
        }
        return {
          ...entry,
          ...(!isSwap && isCombo && hasPrice && { price: pricingFunction(entry, entry.quantity) }),
          children: repriceCartEntriesHelper(entry.children, entry, isCombo ? entry : mainCombo),
        };
      }),
    [isCartEntryInSwaps, pricingFunction, getItemPriceForRepricing, priceForItemOptionModifier]
  );

  const repriceCartEntries = useCallback(
    (entries, parentEntry, mainCombo) => {
      const newEntries = repriceCartEntriesHelper(entries, parentEntry, mainCombo);
      // If newEntries is structurally equal to entries, then return original object
      // to avoid unnecessary re-renders. This is a fix to priceOrder mutation being called twice.
      return isEqual(entries, newEntries) ? entries : newEntries;
    },
    [repriceCartEntriesHelper]
  );

  // recursively reprice cartEntries when prices change
  useEffect(() => {
    if (storeMenuLoading || !prices) {
      return;
    }

    setCartEntries(prevEntries => repriceCartEntries(prevEntries));
    // adding repriceCartEntries to the deps array crashes the browser
    // because of its dependency on pricingFunction
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prices, storeMenuLoading]);

  // show error cart price limit modal
  const isCatering = isCateringOrder(serviceMode);
  useAlertOrderLimit({
    appliedLoyaltyRewards,
    calculateCartTotal,
    checkoutPriceLimit,
    checkoutCateringPriceLimit,
    alertOrderLimit,
    isCatering,
    setCartPriceLimitExceeded,
    setIsCartPriceAboveMinimumSpend,
    setMinimumSpendAmount,
  });
  useAlertOrderDeliveryMinimum({
    cartSubtotal:
      serverOrder?.cart?.subTotalCents ??
      // subtotal without discounts
      computeCartTotal(
        cartEntries,
        {
          loyaltyEnabled,
          appliedLoyaltyRewards,
        },
        isAmountOfferDiscountValueAsCents
      ),
    checkoutDeliveryPriceMinimum,
    isDelivery,
    setCartPriceTooLow,
  });
  useAlertOrderCateringMinimum({
    calculateCartTotal,
    checkoutCateringPriceMinimum,
    isCatering,
    setCartCateringPriceTooLow,
  });

  const handleReorder = useHandleReorder({
    addToCart,
    navigate,
    setPendingReorder,
    setReordering,
    storeHasSelection: isStoreOpenAndAvailable,
    setUnavailableCartEntries,
    setReorderedOrderId,
    cartEntries,
  });

  const isCartTotalGreaterThanDiscountOfferValue = useCallback(() => {
    const total = calculateCartTotal();
    let discountValue = 0;
    if (discountAppliedCmsOffers?.length) {
      discountValue = discountAppliedCmsOffers.reduce((acc, loyaltyCmsOffer) => {
        const discountIncentive = loyaltyCmsOffer?.incentives?.[0];
        const offerDiscountValue =
          discountIncentive?.discountType === OfferDiscountTypes.AMOUNT
            ? discountIncentive?.discountValue || 0
            : 0;

        return acc + offerDiscountValue;
      }, 0);
    }

    // if the discount value is as cents, need to compare with total, other than total / 100
    return isAmountOfferDiscountValueAsCents ? discountValue > total : discountValue > total / 100;
  }, [calculateCartTotal, discountAppliedCmsOffers, isAmountOfferDiscountValueAsCents]);
  const cartPreviewEntries = changeImageByChannel(cartEntries);

  const isCartEmpty = !cartPreviewEntries.length;
  const canUserCheckout = !isCartEmpty && !cartPriceLimitExceeded;
  const numCartPreviewEntries = useMemo(
    () => (cartPreviewEntries || []).reduce((accum, entry) => accum + entry.quantity ?? 1, 0),
    [cartPreviewEntries]
  );

  const selectStore = useCallback(
    async (newStore, callback, requestedServiceMode) => {
      setFetchingPosData(true);
      const startTime = new Date().valueOf();
      const selectedStorePrices = await getPosData({
        restaurantPosDataId: newStore.restaurantPosData?._id || '',
        storeNumber: newStore.number,
      });

      const unavailableItems = getUnavailableCartEntries(
        cartEntries || [],
        newStore,
        selectedStorePrices?.posData || prices,
        requestedServiceMode
      );

      const selectNewStoreCallback = () => {
        if (unavailableItems.length) {
          removeAllFromCart(unavailableItems);
        }
        callback();
      };

      const updateCallback = async () => {
        await selectNewStore({
          sanityStore: newStore,
          hasCartItems: !isCartEmpty,
          unavailableCartEntries: unavailableItems,
          callback: selectNewStoreCallback,
          requestedServiceMode,
        });
        setFetchingPosData(false);
        const endTime = new Date().valueOf();
        const loadingTime = endTime - startTime;

        DatadogLogger.dataDogLogger({
          message: `[store-select] store selected`,
          context: {
            loadingTime,
          },
          status: DatadogLogger.StatusType.info,
        });
      };

      if (isDelivery) {
        updateCallback();
      } else {
        updateUserStoreWithCallback(newStore, updateCallback);
      }
    },
    [
      cartEntries,
      getPosData,
      isCartEmpty,
      isDelivery,
      prices,
      removeAllFromCart,
      selectNewStore,
      updateUserStoreWithCallback,
      setFetchingPosData,
    ]
  );

  const [repriceOrderAfterNavigate, setRepriceOrderAfterNavigate] = useState(false);

  const value = useMemo(
    () => ({
      // state
      additionalDetailsInfo,
      setAdditionalDetailsInfo,
      cartEntries,
      numCartPreviewEntries,
      cartVersion: CART_VERSION,
      unavailableCartEntries,
      serviceMode,
      serverOrder,
      cartPriceLimitExceeded,
      cartPriceTooLow,
      cartCateringPriceTooLow,
      cartHasRewardEligibleItem,
      // Catering
      cateringPickupDateTime,
      setOrderCateringPickupDateTime,
      // Cart
      checkoutPriceLimit,
      checkoutDeliveryPriceMinimum,
      checkoutCateringPriceLimit,
      checkoutCateringPriceMinimum,
      alertOrderLimit,
      alertOrderDeliveryMinimum,
      alertOrderCateringMinimum,
      confirmRemoveFromCart,
      removeFromCart,
      removeAllFromCart,
      setUnavailableCartEntries,
      isCartPriceAboveMinimumSpend,
      minimumSpendAmount,
      editCart,
      cartIdEditing,
      getCurrentCartEntry,
      addToCart,
      upsertCart,
      updateCartEntry,
      updateQuantity,
      calculateCartTotal,
      calculateCartTotalWithDiscount,
      calculateCartTotalWithoutOffers,
      emptyCart,
      tipAmount,
      setTipAmount,
      updateTipAmount,
      tipSelection,
      setTipSelection,
      verifyCartVersion,
      repriceCartEntries,
      shouldShowTipPercentage,
      isCartTotalGreaterThanDiscountOfferValue,
      isCartEmpty,
      cartPreviewEntries,
      canUserCheckout,
      swapItems,
      isCartEntryInSwaps,
      // Order
      selectServiceMode,
      clearServerOrder,
      selectStore,
      fetchingPosData,
      clearCartStoreServiceModeTimeout,
      logCartStoreAndTimeout,
      deliveryAddress,
      setDeliveryAddress,
      deliveryInstructions,
      setDeliveryInstructions,
      selectedPreOrderTimeSlot,
      setSelectedPreOrderTimeSlot,
      preselectedPreOrderTimeSlot,
      setPreselectedPreOrderTimeSlot,
      orderPhoneNumber,
      setOrderPhoneNumber,
      orderPhoneNumberError,
      setOrderPhoneNumberError,
      isCatering,
      isDelivery,
      fireOrderIn,
      setFireOrderIn,
      logOrderLatencyDuration,
      onCommitSuccess,
      tipPercentThresholdCents,
      curbsidePickupOrderId,
      updateShouldSaveDeliveryAddress,
      setCurbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
      setCurbsidePickupOrderTimePlaced,
      setQuoteId,
      quoteId,
      // Server Interactions
      price,
      query: getAndRefreshServerOrder,
      fireOrderInXSeconds,
      isUpdatingOrder,
      cancelOrder,
      savedDeliveryAddress,
      setSavedDeliveryAddress,
      reorder: {
        handleReorder,
        reordering,
        pendingReorder,
        setReordering,
        setPendingReorder,
        reorderedOrderId,
      },
      recent: {
        setPendingRecentItem,
        pendingRecentItem,
        pendingRecentItemNeedsReprice,
        setPendingRecentItemNeedsReprice,
      },
      isPricingOrder,
      setIsPricingOrder,
      filterAdditionDetails,
      repriceOrderAfterNavigate,
      setRepriceOrderAfterNavigate,
    }),
    [
      CART_VERSION,
      additionalDetailsInfo,
      addToCart,
      alertOrderCateringMinimum,
      alertOrderDeliveryMinimum,
      alertOrderLimit,
      calculateCartTotal,
      calculateCartTotalWithDiscount,
      calculateCartTotalWithoutOffers,
      canUserCheckout,
      cancelOrder,
      cartCateringPriceTooLow,
      cartEntries,
      cartHasRewardEligibleItem,
      cartIdEditing,
      cartPreviewEntries,
      cartPriceLimitExceeded,
      cartPriceTooLow,
      cateringPickupDateTime,
      checkoutCateringPriceLimit,
      checkoutCateringPriceMinimum,
      checkoutDeliveryPriceMinimum,
      checkoutPriceLimit,
      clearCartStoreServiceModeTimeout,
      confirmRemoveFromCart,
      curbsidePickupOrderId,
      curbsidePickupOrderTimePlaced,
      deliveryAddress,
      deliveryInstructions,
      editCart,
      emptyCart,
      fetchingPosData,
      fireOrderIn,
      fireOrderInXSeconds,
      isUpdatingOrder,
      getAndRefreshServerOrder,
      getCurrentCartEntry,
      handleReorder,
      isCartEmpty,
      isCartEntryInSwaps,
      isCartTotalGreaterThanDiscountOfferValue,
      isCatering,
      isDelivery,
      isPricingOrder,
      logCartStoreAndTimeout,
      logOrderLatencyDuration,
      numCartPreviewEntries,
      onCommitSuccess,
      orderPhoneNumber,
      orderPhoneNumberError,
      pendingRecentItem,
      pendingRecentItemNeedsReprice,
      pendingReorder,
      price,
      quoteId,
      removeAllFromCart,
      removeFromCart,
      reorderedOrderId,
      reordering,
      repriceCartEntries,
      savedDeliveryAddress,
      selectServiceMode,
      selectStore,
      serverOrder,
      serviceMode,
      setTipAmount,
      setTipSelection,
      setUnavailableCartEntries,
      shouldShowTipPercentage,
      swapItems,
      tipAmount,
      tipPercentThresholdCents,
      tipSelection,
      unavailableCartEntries,
      updateCartEntry,
      updateQuantity,
      updateShouldSaveDeliveryAddress,
      updateTipAmount,
      upsertCart,
      verifyCartVersion,
      repriceOrderAfterNavigate,
      setRepriceOrderAfterNavigate,
    ]
  );

  return (
    <OrderContext.Provider value={value}>
      {props.children}

      <OrderLimitDialog
        body={orderLimitMessage(checkoutPriceLimit, checkoutCateringPriceLimit, isCatering)}
        heading={formatMessage({ id: 'orderLimitHeading' })}
      />
      <RemoveItemDialog
        body={removeItemMessage(itemToRm)}
        heading={formatMessage({ id: 'removeItem' })}
      />

      <UpsellModal
        isOpen={showUpsellModal}
        title={formatMessage({ id: 'upsellModalTitle' })}
        onClose={() => {
          setShowUpsellModal(false);
        }}
      />
    </OrderContext.Provider>
  );
}

export default OrderContext.Consumer;
