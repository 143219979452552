import styled from 'styled-components';

export const CartPreviewContainer = styled.div`
  background: ${Styles.color.white};
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 25.875rem;

  a {
    width: 100%;
  }
`;
