import React from 'react';

import { VisuallyHidden } from '@rbilabs/components-library';
import { useIntl } from 'react-intl';

import Picture from 'components/picture';
import { useFeatureLoyaltyUi } from 'hooks/use-feature-loyalty-ui';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';

import { RewardsLinkLogoWrapper, RewardsLinkWrapper } from './styled';

export const RewardsLink: React.FC = () => {
  const { formatMessage } = useIntl();
  const { featureLoyaltyUi, featureLoyaltyUiLoading } = useFeatureLoyaltyUi();
  const { loyaltyUser } = useLoyaltyUser();
  const isLoyaltyMember = typeof loyaltyUser?.points === 'number';

  const description = isLoyaltyMember
    ? formatMessage({ id: 'rewardsPointsAmount' }, { points: loyaltyUser?.points })
    : formatMessage({ id: 'rewards' });
  const rewardsLink =
    featureLoyaltyUi?.loyaltyCTAButtons?.loyaltyBannerHomepageAuthenticatedButton
      ?.loyaltyBannerHomepageAuthenticatedLink?.locale ||
    formatMessage({ id: 'routes.loyaltyDashboard' });
  const rewardsLogo = featureLoyaltyUi?.rewardsLogo?.locale;

  const shouldDisplay = !featureLoyaltyUiLoading && rewardsLogo;

  return shouldDisplay ? (
    <RewardsLinkWrapper to={rewardsLink}>
      <VisuallyHidden>{description}</VisuallyHidden>
      <RewardsLinkLogoWrapper isMember={isLoyaltyMember}>
        <Picture image={rewardsLogo} alt={formatMessage({ id: 'rewards' })} objectFitContain />
      </RewardsLinkLogoWrapper>
    </RewardsLinkWrapper>
  ) : null;
};
