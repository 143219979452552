import React from 'react';

import { useIntl } from 'react-intl';
import styled from 'styled-components';

import BlockRenderer from 'components/sanity-block-renderer';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';

const Wrapper = styled.div`
  padding-block-start: 1rem;
  border-block-start: 1px solid black;
  font-size: 0.625rem;
`;

const CalorieDisclaimer = styled(BlockRenderer)`
  & a {
    font-size: 0.625rem;
    text-decoration: underline;
    color: black;
  }
`;

const Disclaimer = (prop: { showCalorieDisclaimer: boolean } = { showCalorieDisclaimer: true }) => {
  const { formatMessage } = useIntl();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { calorie, price, images } = featureDisclaimers || {};

  return (
    <Wrapper>
      {prop.showCalorieDisclaimer && <CalorieDisclaimer content={calorie?.localeRaw} />}
      <p> {formatMessage({ id: 'trademarkAndCopyrightPopeyes' })}</p>
      <p>{price?.locale}</p>
      <p>{images?.locale}</p>
    </Wrapper>
  );
};

export default Disclaimer;
