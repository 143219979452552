import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid ${Styles.color.grey.four};
  border-radius: 25px;
  font-size: 1.5rem;
  line-height: 1;
  color: ${Styles.color.primary};
  margin: 0;
  padding: 0;
  height: 30px;
  width: 30px;
  font-family: ${Styles.fontFamily.base};
  &[disabled] {
    color: ${Styles.color.grey.four};
  }
  align-items: center;
`;

export default Button;
