import * as React from 'react';

import { useIntl } from 'react-intl';

import { CustomEventNames } from 'state/cdp/constants';

import RewardsLink from './rewards-link.base';

const RewardsLinkPLK = () => {
  const { formatMessage } = useIntl();
  return (
    <RewardsLink
      onClickEventName={CustomEventNames.BUTTON_CLICK_PLK_REWARDS}
      textLabel={formatMessage({ id: 'plkRewards' })}
    />
  );
};
export default RewardsLinkPLK;
