import { removeLanguageFromPath } from 'utils/routing';
import { parseUrl } from 'utils/url';

/**
 * Normalizes a DD-RUM view URL s.t. language codes are removed from paths.
 */
export const normalizeUrl = (url: string): string => {
  const urlObject = parseUrl(url);
  if (!urlObject) {
    return url;
  }
  urlObject.pathname = removeLanguageFromPath(urlObject.pathname);
  return urlObject.toString();
};
