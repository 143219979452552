// Popeyes CSS

import { LayoutWidth, ZIndex } from './enums';

export default {
  // Extra CSS Properties
  borderRadius: '4px',
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)',
  shadowColor: 'rgba(0, 0, 0, 0.1)',
  shadowOffset: '0 2px',
  shadowRadius: '6px',

  // Color Styles
  color: {
    accent: '#2F818A',
    alert: {
      default: {
        background: '#BB5C16',
        text: '#ffffff',
      },
      info: {
        background: '#ffffff',
        text: '#ffffff',
      },
      success: {
        background: '#F25600',
        text: '#ffffff',
      },
      warning: {
        background: '#c41230',
        text: '#ffffff',
      },
      error: {
        background: '#c41230',
        text: '#ffffff',
      },
      dark: {
        background: '#000000',
        text: '#ffffff',
      },
    },
    black: '#1f1f1f',
    background: '#f5f1ef',
    codeBlockBackground: '#323231',
    contrastBackground: '#FF7D00',
    disabledColor: '',
    disabledBorder: '',
    disabledBackground: '',
    error: '#c41230',
    errorHover: '',
    grey: {
      one: '#4a4a4a',
      two: '#716E6E',
      three: '#767676',
      four: '#dcdcdc',
      five: '#f5f1ef',
      six: '#7e7e7e',
      seven: '#6E6E6E',
      eight: '#b6b6b6',
      nine: '#d0d0d0',
      ten: '#eae7e5',
    },
    headerContrast: '#F25600',
    highlight: '#FF7D00',
    icon: '#59BEC9',
    loyaltyHighlight: '#911987',
    navLinkColor: {
      active: '#F25600',
      hover: '#767676',
    },
    mobileNavButtonColor: '#1F1F1F',
    pricing: '#c90325',
    primary: '#F25600',
    primaryHover: '#AB4600',
    purple: '#94228b',
    secondary: '#BB5C16',
    secondaryHover: '',
    teal: '#00b2a9',
    tertiary: '#c41230',
    white: '#ffffff',
    cardBackground: '#fff',
    dialogBackground: '#fff',
    validated: '#5E8036',
    validateHover: '',
    success: '',
    red: '#d62300',
    rewardEarnText: '#387F49',
    rewardRedeemText: '#D14545',
    footerPolicyText: '#FFFF00',
  },

  // Font Styles
  fontFamily: {
    base: '"chicken_sans_bold", sans-serif',
    body: '"chicken_sans", sans-serif',
    alternative: '"chicken_sans_bold", sans-serif',
    brand: '"chicken_sans_bold", sans-serif',
  },

  // Font Weight Styles
  fontWeight: {
    black: 800,
    heavy: 'bold',
    normal: 'normal',
    light: 300,
  },

  // Font Transforms Styles
  textTransform: {
    headlines: 'none',
    body: 'none',
  },

  // Z Index Styles
  zIndex: {
    min: ZIndex.MIN,
    max: ZIndex.MAX,
    overlay: ZIndex.OVERLAY,
    desktopHeader: ZIndex.DESKTOP_HEADER,
    top: ZIndex.TOP,
    normal: ZIndex.NORMAL,
    below: ZIndex.BELOW,
  },

  // Layout variables
  layout: {
    sectionMaxWidth: `${LayoutWidth.REGULAR}px`,
    smallerSectionMaxWidth: `${LayoutWidth.SMALL}px`,
    checkoutHeaderHeight: '57px',
    navHeight: {
      mobile: '56px',
      desktop: '78px',
    },
  },
};
