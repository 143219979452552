import { useState } from 'react';

import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { useAutoEnrollUserInLoyaltyMutation } from '../../generated/rbi-graphql';

type TEnrollmentState = null | 'enrolling' | 'enrolled';

export const useAutoEnrollUserInLoyalty = () => {
  const enableAutoEnrollment = useFlag(LaunchDarklyFlag.ENABLE_LOYALTY_AUTO_ENROLLMENT);

  const { isAuthenticated, user } = useAuthContext();

  const [enrollmentState, setEnrollmentState] = useState<TEnrollmentState>(null);

  const [createLoyaltyUser] = useAutoEnrollUserInLoyaltyMutation();

  const shouldAutoEnroll =
    enableAutoEnrollment && isAuthenticated() && !user?.loyaltyId && enrollmentState === null;

  if (!shouldAutoEnroll) {
    return;
  }

  const tryToEnroll = async () => {
    setEnrollmentState('enrolling');
    const response = await createLoyaltyUser();

    if (response.data?.autoEnrollUserInLoyalty.loyaltyId) {
      setEnrollmentState('enrolled');
    }
  };

  tryToEnroll();
};
