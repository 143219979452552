import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const RewardsLinkWrapper = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
`;

const rewardsLinkNonMemberStyles = css`
  transition: all 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:hover {
    transform: scale3d(1.2, 1.2, 1);
    transition: all 0.35s cubic-bezier(0, 1.27, 0.46, 1.65);
  }
`;

export const RewardsLinkLogoWrapper = styled.div<{ isMember?: boolean }>`
  width: ${p => (p.isMember ? 5.125 : 7)}rem;
  height: 4rem;
  display: inline-block;
  position: relative;
  ${p => !p.isMember && rewardsLinkNonMemberStyles}
`;

export const UserNameWrapper = styled.div`
  max-width: 5rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;
