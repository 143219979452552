import { useMemo } from 'react';

import { ILoggerOptions } from 'utils/logger';

import { useLoggerContext } from './context';

/**
 * use a logger, with optional params attached
 */
export const useLogger = <ChildFields extends object>(
  fields?: ChildFields,
  options?: ILoggerOptions
) => {
  const { logger } = useLoggerContext();
  const loggerWithFields = useMemo(() => {
    if (!fields && !options) {
      return logger;
    }

    return logger.child(fields || {}, options);
  }, [logger, options, fields]);

  return loggerWithFields;
};
