import { useCallback, useEffect, useState } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { omit } from 'lodash';
import { useIntl } from 'react-intl';

import { IUserFormData } from 'components/user-info-form/types';
import {
  GetMeDocument,
  ICommunicationPreference,
  IDeliveryAddress,
  IFavoriteOffer,
  IFavoriteStore,
  IRequiredAcceptanceAgreementInfo,
  IRequiredAcceptanceAgreementInfoInput,
  IUserDetailsFragment,
  useGetMeQuery,
  useUpdateMeMutation,
} from 'generated/rbi-graphql';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import useEffectOnce from 'hooks/use-effect-once';
import { ModalCb } from 'hooks/use-error-modal';
import { usePrevious } from 'hooks/use-previous';
import { checkForUnexpectedSignOut, getCurrentSession } from 'remote/auth';
import { useAuthGuestContext } from 'state/auth-guest';
import { useCdpContext } from 'state/cdp';
import { CustomEventNames, EventTypes } from 'state/cdp/constants';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { useLoggerContext } from 'state/logger/context';
import { useNetworkContext } from 'state/network';
import LocalStorage from 'utils/cognito/storage';
import { getCustomerIdForCRMStack } from 'utils/environment';
import { StorageKeys } from 'utils/local-storage';
import { toast } from 'utils/toast';

// eslint-disable-next-line import/no-cycle
import {
  CommunicationPreferences,
  FavoriteOffers,
  FavoriteStores,
  UpdateAgreementAcceptance,
} from '..';

import { UserDetails } from './types';
import { useThirdPartyAuthentication } from './use-third-party-authentication';

export * from './types';

export const NUM_RECENT_PURCHASED_ITEMS = 10;

export interface IUseCurrentUser {
  openErrorDialog: ModalCb;
  hasSignedIn: boolean;
  userSession: CognitoUserSession | null;
}

const configUserDetails = (details: IUserDetailsFragment) => ({
  dob: details.dob || '',
  dobDeleted: details.dobDeleted || '',
  email: details.email || '',
  emailVerified: details.emailVerified as boolean,
  name: details.name || '',
  phoneNumber: details.phoneNumber || '',
  phoneVerified: false,
  promotionalEmails: details.promotionalEmails as boolean,
  isoCountryCode: details.isoCountryCode || '',
  zipcode: details.zipcode || '',
  defaultReloadAmt: details.defaultReloadAmt as number,
  defaultAccountIdentifier: details.defaultAccountIdentifier || '',
  defaultFdAccountId: details.defaultFdAccountId || '',
  defaultPaymentAccountId: details.defaultPaymentAccountId || '',
  autoReloadEnabled: details.autoReloadEnabled as boolean,
  autoReloadThreshold: details.autoReloadThreshold as number,
  loyaltyTier: details.loyaltyTier,
  communicationPreferences: (details.communicationPreferences as CommunicationPreferences) || null,
  favoriteStores: (details.favoriteStores as FavoriteStores) || null,
  favoriteOffers: (details.favoriteOffers as FavoriteOffers) || null,
  deliveryAddresses: (details.deliveryAddresses as Array<IDeliveryAddress>) || null,
});

const getPreloadedUserDetails = () => LocalStorage.getItem(StorageKeys.USER);

export const useCurrentUser = ({ openErrorDialog, hasSignedIn, userSession }: IUseCurrentUser) => {
  const { formatMessage } = useIntl();
  const { updateUserAttributes: launchDarklyUpdateUserAttributes } = useLDContext();
  const { decorateLogger, logger } = useLoggerContext();
  const { updateUserAttributes, trackEvent } = useCdpContext();
  const { hasNetworkError, setHasNotAuthenticatedError } = useNetworkContext();
  const { logUserInToThirdPartyServices } = useThirdPartyAuthentication();
  const { signOut: guestSignOut } = useAuthGuestContext();

  const [currentUserSession, setCurrentUserSession] = useState<CognitoUserSession | null>(
    userSession
  );
  const shouldUniqueByModifiers = useFlag(LaunchDarklyFlag.ENABLE_RECENT_ITEMS_WITH_MODIFIERS);
  const getMeVariables = {
    numUniquePurchasedItems: NUM_RECENT_PURCHASED_ITEMS,
    customInput: { shouldUniqueByModifiers },
  };

  const {
    data: userData,
    loading,
    refetch,
    called,
  } = useGetMeQuery({
    skip: !currentUserSession,
    variables: getMeVariables,
    // Apollo client loading state gets stuck: https://github.com/apollographql/react-apollo/issues/3425
    // Temporary fix while we wait for a stable 3.0.0 version
    fetchPolicy: 'cache-and-network',
  });

  // NOTE: The updateMeMutation will attempt to refetch and update userData from useGetMeQuery
  // The refetchQueries query needs to match the useGetMeQuery exactly, including the variables
  const [updateMeMutation, { loading: useUpdateMeMutationLoading }] = useUpdateMeMutation({
    refetchQueries: [{ query: GetMeDocument, variables: getMeVariables }],
    awaitRefetchQueries: true,
  });

  const prevUserData = usePrevious(userData);

  const userInSession = getPreloadedUserDetails();
  const user = currentUserSession ? userData?.me ?? userInSession : null;
  const cognitoId = user?.cognitoId;

  const setCurrentUser = useCallback(
    (session: null | CognitoUserSession, numUniquePurchasedItems?: number) => {
      if (session && called) {
        const refetchVariables = numUniquePurchasedItems
          ? { numUniquePurchasedItems, customInput: { shouldUniqueByModifiers } }
          : undefined;
        refetch(refetchVariables);
      }
      setCurrentUserSession(session);
      if (session) {
        guestSignOut();
      }
    },
    [called, shouldUniqueByModifiers, refetch, guestSignOut]
  );

  const refreshCurrentUser = useCallback(
    async (numUniquePurchasedItems?: number) => {
      try {
        setCurrentUser(currentUserSession, numUniquePurchasedItems);
      } catch (error) {
        logger.error({ error, message: 'An error occurred while refreshing the current user.' });
        openErrorDialog({
          error,
          message: formatMessage({ id: 'authRetryError' }),
          modalAppearanceEventMessage: 'Error: Setting Current User Error',
        });
      }
    },
    [currentUserSession, formatMessage, logger, openErrorDialog, setCurrentUser]
  );

  const refreshCurrentUserWithNewSession = useCallback(
    async (numUniquePurchasedItems?: number) => {
      try {
        const session = await getCurrentSession();
        setCurrentUserSession(session);
        setCurrentUser(currentUserSession, numUniquePurchasedItems);
      } catch (error) {
        logger.error({
          error,
          message: 'An error occurred while refreshing the current user with new session.',
        });
        openErrorDialog({
          error,
          message: formatMessage({ id: 'authRetryError' }),
          modalAppearanceEventMessage: 'Error: Setting Current User Error With New Session',
        });
      }
    },
    [currentUserSession, formatMessage, logger, openErrorDialog, setCurrentUser]
  );

  const updateMParticleAttributes = useCallback(
    (updatedAttributes: IUserFormData | UserDetails['details']) => {
      if (!user) {
        return;
      }

      const userAttributes = {
        customerid: getCustomerIdForCRMStack(user.cognitoId, user.thLegacyCognitoId),
        rbiCognitoId: user.cognitoId,
        ...user.details,
        ...updatedAttributes,
      };

      updateUserAttributes(userAttributes);
    },
    [updateUserAttributes, user]
  );

  const updateUserInfo = useCallback(
    async (form: IUserFormData, shouldMuteUserInfoErrors = false) => {
      try {
        // QUESTION - why do we await the current session here if we don't do anything with it?
        await getCurrentSession();

        let dobDeleted = false;
        if (typeof form.dobDeleted === 'string' || form.dobDeleted instanceof String) {
          dobDeleted = form.dobDeleted.toLowerCase() === 'true';
        } else if (typeof form.dobDeleted === 'boolean' || form.dobDeleted instanceof Boolean) {
          dobDeleted = Boolean(form.dobDeleted);
        }

        updateMParticleAttributes(form);
        launchDarklyUpdateUserAttributes({
          key: user.cognitoId,
          ...form,
        });

        const updateMeParams = omit(
          form,
          'agreesToTermsOfService',
          'defaultCheckoutPaymentMethodId',
          'defaultReloadPaymentMethodId',
          'email',
          'emailVerified',
          'deliveryAddresses'
        );

        const input = {
          ...updateMeParams,
          dobDeleted,
          defaultAccountIdentifier: form.defaultCheckoutPaymentMethodId,
          defaultPaymentAccountId: form.defaultReloadPaymentMethodId,
        };

        await updateMeMutation({ variables: { input } });
      } catch (error) {
        if (!shouldMuteUserInfoErrors) {
          logger.error({ error, message: 'Error: Update User Info Failure' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        } else {
          logger.error({
            error,
            message: 'Error: Update User Info Failure - Muted',
          });
        }
      }
    },
    [
      updateMParticleAttributes,
      launchDarklyUpdateUserAttributes,
      user,
      updateMeMutation,
      logger,
      formatMessage,
    ]
  );

  const updateUserCommPrefs = useCallback(
    async (communicationPreferences: Array<ICommunicationPreference>) => {
      try {
        const promotionalEmailsInput =
          (communicationPreferences.length && {
            promotionalEmails: communicationPreferences.some(
              ({ id, value }) => id === 'marketingEmail' && value === 'true'
            ),
          }) ||
          {};
        const input = {
          communicationPreferences,
          ...promotionalEmailsInput,
        };
        const { data } = await updateMeMutation({ variables: { input } });

        if (!data) {
          return logger.error({ message: 'An error occurred updating communication preference' });
        }

        const details = configUserDetails(data.updateMe.details);
        updateMParticleAttributes(details);
        toast.success(formatMessage({ id: 'successfullyUpdatedCommPreferences' }));
      } catch (error) {
        logger.error({ error, message: 'Error: Update User Communication Preferences Failure' });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [updateMeMutation, updateMParticleAttributes, logger, formatMessage]
  );

  const updateUserFavStores = useCallback(
    async (favoriteStores: Array<IFavoriteStore>) => {
      try {
        const input = { favoriteStores };
        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating favorite store' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        }
      } catch (error) {
        logger.error({ message: `An error occurred updating favorite store: ${error}` });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [formatMessage, logger, updateMeMutation]
  );

  const updateUserLastKnownLoyaltyTier = useCallback(
    async (loyaltyTier: string) => {
      try {
        const input = { loyaltyTier };
        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating last known loyalty tier' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        }
      } catch (error) {
        logger.error({ message: `An error occurred updating last known loyalty tier: ${error}` });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [formatMessage, logger, updateMeMutation]
  );

  const updateUserFavOffers = useCallback(
    async (favoriteOffers: Array<IFavoriteOffer>) => {
      try {
        const input = { favoriteOffers };
        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating favorite offers' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        }
      } catch (error) {
        logger.error({ message: `An error occurred updating favorite offers: ${error}` });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [formatMessage, logger, updateMeMutation]
  );

  const updateUserPhoneNumber = useCallback(
    async (phoneNumber: string) => {
      try {
        const input = { phoneNumber };
        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating phone number' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        }
        toast.success(formatMessage({ id: 'phoneNumberUpdatedSuccess' }));
      } catch (error) {
        logger.error({ message: `An error occurred updating phone number: ${error}` });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [formatMessage, logger, updateMeMutation]
  );

  const checkIfUnexpectedSignOut = useCallback(async () => {
    const errorUnexpectedSignOut = await checkForUnexpectedSignOut();
    if (errorUnexpectedSignOut) {
      logger.error({
        message: 'Unexpected Sign out',
        error: errorUnexpectedSignOut,
      });
      // sent mParticle event telling that there has been an unexpected sign out
      trackEvent({
        name: CustomEventNames.UNEXPECTED_SIGN_OUTS,
        type: EventTypes.Other,
        attributes: {
          cognitoId: prevUserData?.me?.cognitoId,
          error: errorUnexpectedSignOut,
        },
      });
    }
  }, [trackEvent, logger, prevUserData]);

  useEffect(() => {
    // decorate logger with cognito id
    decorateLogger({ userId: cognitoId });
  }, [decorateLogger, cognitoId]);

  useEffect(() => {
    if (!user) {
      checkIfUnexpectedSignOut();
    }
    LocalStorage.setItem(StorageKeys.USER, user);
  }, [checkIfUnexpectedSignOut, prevUserData, user]);

  useEffectOnce(() => {
    const refreshUserIfHasSignedIn = async () => {
      if (!hasSignedIn) {
        setHasNotAuthenticatedError(true);
      }
    };
    refreshUserIfHasSignedIn();
  });

  useEffectOnUpdates(() => {
    // any update fetch + set current user
    if (!hasNetworkError) {
      refreshCurrentUser();
    }
  }, [hasNetworkError]);

  // when user data populates for the first time, it means the user got signed in, therefore we should sign them into all third party services as well
  useEffect(() => {
    if (userData && !prevUserData) {
      logUserInToThirdPartyServices(userData.me as unknown as UserDetails);
    }
  }, [userData, prevUserData, logUserInToThirdPartyServices]);

  const userMarketingPrefs = useCallback(
    (prefId: string) => {
      const marketingPrefId = prefId === 'email_subscribe' ? 'marketingEmail' : 'marketingPush';

      const marketingSavedValue = (user?.details?.communicationPreferences || []).find(
        (prefs: { id: string }) => prefs.id === marketingPrefId
      );

      return marketingSavedValue?.value;
    },
    [user]
  );

  const updateRequiredAcceptanceAgreementInfo = useCallback(
    async (updateAgreementAcceptance: UpdateAgreementAcceptance): Promise<any> => {
      try {
        const { acceptanceFromSanity, emailMarketing } = updateAgreementAcceptance;

        const newSanityAcceptanceMapper = acceptanceFromSanity.map(sp => {
          return { id: sp._id, updatedAt: sp._updatedAt } as IRequiredAcceptanceAgreementInfoInput;
        });

        const userAcceptanceUpdated = (user.details?.requiredAcceptanceAgreementInfo || []).filter(
          (requiredAcceptanceAgreement: IRequiredAcceptanceAgreementInfo) => {
            const exist = newSanityAcceptanceMapper.find(
              sp => sp.id === requiredAcceptanceAgreement.id
            );

            if (!exist) {
              return requiredAcceptanceAgreement as IRequiredAcceptanceAgreementInfoInput;
            }

            return null;
          }
        );

        const newUpdateAcceptanceAgreement =
          newSanityAcceptanceMapper.concat(userAcceptanceUpdated);

        const communicationPreferences = (user?.details?.communicationPreferences || []).map(
          (comm: { id: string; value: string }) => {
            let value = String(comm.value);

            if (comm.id === 'marketingEmail' || comm.id === 'marketingPush') {
              const marketingEmailValue = emailMarketing || comm.value;
              value = String(marketingEmailValue);
            }

            if (comm.id === 'email_subscribe' || comm.id === 'push_subscribe') {
              const marketingEmailValue = emailMarketing || userMarketingPrefs(comm.id) === 'true';
              value = marketingEmailValue ? 'opted_in' : 'unsubscribed';
            }

            return {
              id: comm.id,
              value,
            };
          }
        );

        const input = {
          requiredAcceptanceAgreementInfo: newUpdateAcceptanceAgreement,
          communicationPreferences,
        };

        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating acceptance agreement' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        }

        return data;
      } catch (error) {
        logger.error({ message: `An error occurred updating acceptance agreement: ${error}` });
        toast.error(formatMessage({ id: 'updateInfoError' }));
        return null;
      }
    },
    [formatMessage, logger, updateMeMutation, user, userMarketingPrefs]
  );

  const upsateUserPiiInfo = useCallback(
    async (userPii: Record<string, unknown>) => {
      try {
        const input = { additionalDetails: JSON.stringify(userPii) };
        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating PII details' });
          toast.error(formatMessage({ id: 'updateInfoError' }));
        }
      } catch (error) {
        logger.error({ message: `An error occurred updating PII details: ${error}` });
        toast.error(formatMessage({ id: 'updateInfoError' }));
      }
    },
    [formatMessage, logger, updateMeMutation]
  );

  return {
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    setCurrentUser,
    updateUserCommPrefs,
    updateUserFavStores,
    updateUserFavOffers,
    updateUserPhoneNumber,
    updateUserInfo,
    user,
    currentUserSession,
    setCurrentUserSession,
    userLoading: loading,
    useUpdateMeMutationLoading,
    updateRequiredAcceptanceAgreementInfo,
    updateUserLastKnownLoyaltyTier,
    upsateUserPiiInfo,
  };
};
