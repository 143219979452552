import styled from 'styled-components';

export const WordmarkSocialIconsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 1.125rem 0;

  ${Styles.desktop} {
    justify-content: flex-end;

    :first-child {
      padding-block-start: 0;
    }

    :last-child {
      padding-block-end: 0;
    }
  }
`;
