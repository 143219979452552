import { onError } from '@apollo/client/link/error';

import { ILogger } from 'utils/logger';

import { REQUEST_CANCELLED_KEY } from './cancel-request-link';

export const withErrorLogger = onError(({ operation, graphQLErrors }) => {
  const logger: ILogger = operation.getContext().logger;

  if (
    !graphQLErrors ||
    !logger ||
    graphQLErrors.some(err => err.extensions?.[REQUEST_CANCELLED_KEY])
  ) {
    return;
  }

  const logError = (props?: any) => {
    /*
     * Default a message string.
     * TODO: Identified these messages and provide more specific message?
     * - Could use the graphQLErrors?.[0]?.extensions?.code as the message?
     */
    return logger.error({
      message: 'Generic graphQL Error - withErrorLogger Link',
      ...props,
      errors: graphQLErrors,
      queryName: operation.operationName,
    });
  };

  return logError();
});
