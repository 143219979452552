import React from 'react';

import { createRoot } from 'react-dom/client';

import * as location from 'utils/location';

import App from './app';
import bootstrap, { RenderApp } from './bootstrap';

const searchParams = location.getSearchParams();
const enableWhyDidYouRender = searchParams.get('enableWhyDidYouRender');
if (process.env.NODE_ENV !== 'production' && !window.Cypress && enableWhyDidYouRender === 'true') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

export const renderApp: RenderApp = ({
  appLanguage,
  appCountry,
  flags: flattenedFlags,
  userHasSignedIn,
  userSession,
  shouldUseLanguageCodeInUrls,
}) => {
  const rootTag: HTMLElement = document.getElementById('root') as HTMLElement;
  const root = createRoot(rootTag);

  root.render(
    <App
      shouldUseLanguageCodeInUrls={shouldUseLanguageCodeInUrls}
      appLanguage={appLanguage}
      appCountry={appCountry}
      flags={flattenedFlags}
      userHasSignedIn={userHasSignedIn}
      userSession={userSession}
    />
  );
};

bootstrap(renderApp);
