import styled from 'styled-components';

import UnstyledButton from 'components/unstyled-button';

export const CartItemButton = styled(UnstyledButton)`
  cursor: pointer;
  color: ${Styles.color.grey.three};
  font-size: 0.75rem;
  text-transform: capitalize;
`;
