import styled from 'styled-components';

import {
  StyledDiscountPrice as StyledDiscountPriceDefault,
  StyledPointsDisplay as StyledPointsDisplayDefault,
  CartItemPrice as StyledPrice,
} from './styled.default';

export * from './styled.default';

export const StyledDiscountPrice = styled(StyledDiscountPriceDefault)<{ isVisible: boolean }>`
  color: ${Styles.color.black};
`;

export const CartItemPrice = styled(StyledPrice)`
  font-size: 1rem;
  ${p => p.isOffer && `color: ${Styles.color.black};`}
`;

export const StyledPointsDisplay = styled(StyledPointsDisplayDefault)`
  color: ${Styles.color.primary};
`;
