import { datadogRum } from '@datadog/browser-rum';

import { useBranchContext } from 'state/branch';
import { CdpProviderTypes, useCdpContext } from 'state/cdp';
import { initDatadogRum } from 'utils/datadog';
import LaunchDarklyHelper, { LaunchDarklyFlag, getExtraContext } from 'utils/launchdarkly';

import { isBranchUnavailable } from './cookiebot-cookie-banner/utils';

const config = {
  [CdpProviderTypes.MParticle]: 'mParticle',
  [CdpProviderTypes.Bloomreach]: 'exponea',
};
export default function useInitializeThirdPartyServices() {
  const { init } = useCdpContext();
  const { initBranch } = useBranchContext();
  const branchUnavailable = isBranchUnavailable();

  const initServicesWithCookies = () => {
    const allFlags = LaunchDarklyHelper.getInstance().launchDarkly.allFlags();
    const flattenedFlags = LaunchDarklyHelper.flattenFlags(allFlags);
    if (!datadogRum.getInitConfiguration()) {
      const sampleRate = flattenedFlags[LaunchDarklyFlag.DATADOG_RUM_SAMPLE_RATE] || 0;
      const replaySampleRate = flattenedFlags[LaunchDarklyFlag.DATADOG_RUM_REPLAY_SAMPLE_RATE] || 0;
      initDatadogRum(getExtraContext(), sampleRate, replaySampleRate);
    }
    const cdpProvider = flattenedFlags[LaunchDarklyFlag.CDP_PROVIDER] as CdpProviderTypes;
    const provider =
      (config[cdpProvider] as string) ?? (config[CdpProviderTypes.MParticle] as string);

    if (!window?.[provider as any]) {
      init();
    }

    if (!window.branch && !branchUnavailable) {
      initBranch();
    }
  };

  return { initServicesWithCookies };
}
