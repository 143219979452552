import ThemeBase from './theme-base';

export default {
  ...ThemeBase,
  delivery: {
    ...ThemeBase.delivery,
    icon: ThemeBase.delivery.icon,
    height: '2.15rem',
    width: '2.4rem',
  },
  pickup: {
    ...ThemeBase.pickup,
    icon: 'poppyBag',
    height: '2.15rem',
    width: '2.15rem',
  },
  catering: {
    ...ThemeBase.catering,
    delivery: {
      ...ThemeBase.catering.delivery,
      icon: ThemeBase.catering.delivery.icon,
      height: '2.15rem',
      width: '2.4rem',
    },
  },
};
