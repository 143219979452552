import React from 'react';

import { OnClick } from '@rbilabs/components-library/build/components/button';
import { Icon } from '@rbilabs/components-library/build/components/icon';
import { VisuallyHidden } from '@rbilabs/components-library/build/components/visually-hidden';
import { useIntl } from 'react-intl';

import { ActionButtonSizes, ActionButtonVariants, ActionLink } from 'components/action-button';
import { useAuthContext } from 'state/auth';
import { routes } from 'utils/routing';

import { UserNameWrapper } from './styled';

interface IUserAccountButton {
  onClick: OnClick;
}

export const UserAccountButton: React.FC<IUserAccountButton> = ({ onClick }) => {
  const { formatMessage } = useIntl();
  const { user } = useAuthContext();

  return (
    <ActionLink
      variant={ActionButtonVariants.INVERSE}
      size={ActionButtonSizes.SMALL}
      transparent
      to={routes.account}
      onClick={onClick}
      data-private
      data-dd-privacy="mask"
      data-testid="my-account-link"
      title={formatMessage({ id: 'myAccount' })}
      onlyIcon
    >
      <Icon icon="profile" color="white" aria-hidden />
      <VisuallyHidden>{formatMessage({ id: 'myAccount' })}</VisuallyHidden>
      <UserNameWrapper>{user?.details?.name}</UserNameWrapper>
    </ActionLink>
  );
};
