/* eslint-disable react-hooks/rules-of-hooks */
import React, { Fragment, ReactNode, lazy } from 'react';

import { useBloomreachContext } from 'state/cdp/bloomreach';
import { useMParticleContext } from 'state/cdp/mParticle';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import * as I from './types';

export enum CdpProviderTypes {
  MParticle = 'mparticle',
  Bloomreach = 'bloomreach',
}
const providersMap = {
  mparticle: lazy(() =>
    import('state/cdp/mParticle').then(module => ({ default: module.MParticleProvider }))
  ),
  bloomreach: lazy(() =>
    import('state/cdp/bloomreach').then(module => ({ default: module.BloomreachProvider }))
  ),
};

const providersContext = {
  mparticle: useMParticleContext,
  bloomreach: useBloomreachContext,
};

const CDP_PROVIDER_DEFAULT = CdpProviderTypes.MParticle;

function getProvider() {
  return useFlag<CdpProviderTypes>(LaunchDarklyFlag.CDP_PROVIDER) ?? CDP_PROVIDER_DEFAULT;
}

export function CdpProvider(props: { children: ReactNode }) {
  const provider = getProvider();
  const MappedCdpProvider = providersMap[provider] ?? Fragment;

  return <MappedCdpProvider>{props.children}</MappedCdpProvider>;
}

export function useCdpContext(): I.ICdpCtx {
  const provider = getProvider();
  return providersContext[provider] ? providersContext[provider]() : useMParticleContext();
}
