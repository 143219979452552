import { ApolloLink, NextLink, Operation } from '@apollo/client';
import { merge } from 'lodash';

import defaultLogger, { ILogger } from 'utils/logger';

export class WithLoggerLink extends ApolloLink {
  public logger: ILogger;

  constructor() {
    super();
    this.logger = defaultLogger;
  }

  public request(operation: Operation, forward: NextLink) {
    const context = operation.getContext();

    operation.setContext(merge({ logger: this.logger }, context));

    return forward(operation);
  }

  public setLogger(logger: ILogger): ILogger {
    this.logger = logger;
    return logger;
  }
}

/**
 * use a singleton instance to share logger across requests
 */
export const withLogger = new WithLoggerLink();
