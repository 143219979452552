import React, { SVGAttributes } from 'react';

const LoyaltyMyCodeIcon: React.FC<SVGAttributes<SVGElement>> = ({ ...props }) => {
  const fillColor = props.fill || 'none';

  return (
    <svg
      data-dir="ltr"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 10.6667H10.6667V0H0V10.6667ZM2.66667 2.66667H8V8H2.66667V2.66667Z" fill="white" />
      <path d="M0 24H10.6667V13.3333H0V24ZM2.66667 16H8V21.3333H2.66667V16Z" fill="white" />
      <path d="M13.3333 0V10.6667H24V0H13.3333ZM21.3333 8H16V2.66667H21.3333V8Z" fill="white" />
      <path d="M24 21.3333H21.3333V24H24V21.3333Z" fill="white" />
      <path d="M16 13.3333H13.3333V16H16V13.3333Z" fill="white" />
      <path d="M18.6667 16H16V18.6667H18.6667V16Z" fill="white" />
      <path d="M16 18.6667H13.3333V21.3333H16V18.6667Z" fill="white" />
      <path d="M18.6667 21.3333H16V24H18.6667V21.3333Z" fill="white" />
      <path d="M21.3333 18.6667H18.6667V21.3333H21.3333V18.6667Z" fill="white" />
      <path d="M21.3333 13.3333H18.6667V16H21.3333V13.3333Z" fill="white" />
      <path d="M24 16H21.3333V18.6667H24V16Z" fill="white" />
    </svg>
  );
};

export default LoyaltyMyCodeIcon;
