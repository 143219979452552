import * as React from 'react';
import { FC } from 'react';

import { Icon } from '@rbilabs/components-library/build/components/icon';
import { useIntl } from 'react-intl';

import { IBaseProps } from '@rbi-ctg/frontend';
import ActionButton, { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import LoadingAnimation from 'components/loading-animation';
import { ILocaleStringFragment } from 'generated/sanity-graphql';
import useDialogModal from 'hooks/use-dialog-modal';
import {
  ErrorMessage,
  ErrorMessageTitle,
  ShortCodeContainer,
  ShortCodeContent,
  ShortCodeErrorContainer,
  ShortCodeSubtitle,
  ShortCodeTitle,
  StyledRefreshIcon,
} from 'pages/loyalty/loyalty-in-restaurant-redemption/loyalty-in-restaurant-redemption.styled';
import { IRefreshCodeProps } from 'pages/loyalty/loyalty-in-restaurant-redemption/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import {
  ShortCodeState,
  useInRestaurantRedemptionContext,
} from 'state/loyalty/in-restaurant-redemption';
import { addStringInBetween } from 'utils/add-string-in-between';

import { InRestaurantRedemptionRewardList } from '../../loyalty-in-restaurant-redemption/reward-redemption-list';

import {
  Code,
  Container,
  GenerateButton,
  IconContainer,
  Title,
} from './loyalty-short-code-widget.styled';

const ShortCodeErrorBox: FC<IRefreshCodeProps> = ({ onRefreshClick, isCodeLoading, children }) => {
  const { formatMessage } = useIntl();

  return (
    <ShortCodeErrorContainer data-testid="short-code-error-box">
      {children}
      {
        <ActionButton
          isLoading={isCodeLoading}
          loadingColor={Styles.color.white}
          onClick={onRefreshClick}
        >
          <StyledRefreshIcon />
          {formatMessage({ id: 'refresh' })}
        </ActionButton>
      }
    </ShortCodeErrorContainer>
  );
};

export interface ILoyaltyShortCodeWidgetProps extends IBaseProps {
  defaultCode: ILocaleStringFragment;
  title: ILocaleStringFragment;
  alternativeTitle: ILocaleStringFragment;
  subtitle: ILocaleStringFragment;
  className?: string;
}

const LoyaltyShortCodeWidget: FC<ILoyaltyShortCodeWidgetProps> = ({
  title,
  subtitle,
  alternativeTitle,
  className,
}) => {
  const { shortCodeState, shortCodeLoading, shortCode, generateShortCode } =
    useInRestaurantRedemptionContext();

  const { formatMessage } = useIntl();
  const enableLoyaltyShortCodeUpdatedDesign = useFlag(
    LaunchDarklyFlag.ENABLE_LOYALTY_SHORT_CODE_UPDATED_DESIGN
  );

  const [InfoDialog, openInfoDialog] = useDialogModal({
    modalAppearanceEventMessage: 'Short Code Info',
  });

  const generateCodeButton = (
    <GenerateButton
      variant={ActionButtonVariants.OUTLINE}
      data-testid="loyalty-short-code-generate-new-code"
      size={ActionButtonSizes.LARGE}
      perceptible
      onClick={generateShortCode}
      isLoading={shortCodeLoading}
      startIcon={<Icon icon={'refresh'} color={'primary'} width="20px" aria-hidden />}
    >
      {formatMessage({ id: 'generateNewCode' })}
    </GenerateButton>
  );

  if (enableLoyaltyShortCodeUpdatedDesign) {
    return (
      <>
        <Container data-testid="short-code-widget">
          {shortCodeState === ShortCodeState.Pending && (
            <IconContainer data-testid="short-code-info" onClick={openInfoDialog}>
              <Icon icon={'info'} color={'tertiary'} width="24px" aria-hidden />
            </IconContainer>
          )}

          {shortCodeState === ShortCodeState.RequestError ? (
            <Title>{formatMessage({ id: 'codeErrorMessage' })}</Title>
          ) : (
            <>
              {shortCodeState === ShortCodeState.Expired ? (
                <Title>{alternativeTitle.locale}</Title>
              ) : (
                <Title>{title.locale}</Title>
              )}
            </>
          )}

          {shortCodeState === ShortCodeState.Pending && (
            <>
              {shortCode ? (
                <Code>{addStringInBetween(shortCode || '', 2, ' ')}</Code>
              ) : (
                generateCodeButton
              )}
            </>
          )}

          {shortCodeState === ShortCodeState.Expired && generateCodeButton}
        </Container>

        <InRestaurantRedemptionRewardList loyaltyEmptyCartTileWidget={null} />
        <InfoDialog heading="" body={subtitle?.locale} />
      </>
    );
  }

  // TODO Remove this after we remove the ENABLE_LOYALTY_SHORT_CODE_UPDATED_DESIGN flag
  return (
    <>
      <ShortCodeContainer className={className}>
        <ShortCodeTitle>{title?.locale || alternativeTitle?.locale}</ShortCodeTitle>
        <ShortCodeSubtitle>{subtitle?.locale}</ShortCodeSubtitle>
        {([ShortCodeState.Pending, ShortCodeState.Claimed].includes(shortCodeState) ||
          (shortCodeState === ShortCodeState.None && shortCodeLoading)) && (
          <ShortCodeContent data-testid="short-code">
            {shortCodeLoading ? <LoadingAnimation /> : addStringInBetween(shortCode || '', 2, ' ')}
          </ShortCodeContent>
        )}
        {shortCodeState === ShortCodeState.Expired && (
          <ShortCodeErrorBox
            onRefreshClick={generateShortCode}
            isCodeLoading={shortCodeLoading}
          ></ShortCodeErrorBox>
        )}
        {shortCodeState === ShortCodeState.RequestError && (
          <ShortCodeErrorBox onRefreshClick={generateShortCode} isCodeLoading={shortCodeLoading}>
            <ErrorMessageTitle>{formatMessage({ id: 'codeErrorMessage' })}</ErrorMessageTitle>
            <ErrorMessage>{formatMessage({ id: 'pleaseTryToRefresh' })}</ErrorMessage>
          </ShortCodeErrorBox>
        )}
      </ShortCodeContainer>
      <InRestaurantRedemptionRewardList loyaltyEmptyCartTileWidget={null} />
    </>
  );
};

export default LoyaltyShortCodeWidget;
