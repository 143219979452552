import styled from 'styled-components';

import { IIconContainerProps } from './types';

export const IconContainer = styled.div<IIconContainerProps>`
  background-color: ${Styles.color.primary};
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 50%;
    height: 50%;
    fill: ${Styles.color.white};
  }

  &:hover {
    background-color: ${Styles.color.primaryHover};
  }
`;
